import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ThemeEnum } from "@det/core/common-enums";
import { GlobalConstants } from '@det/core/global-constants';
import { HttpService } from '@det/core/http.service';
import { ResponseModel } from '@det/core/common.model';

@Injectable()
export class ThemeService {
  private renderer: Renderer2;
  private _darkTheme = new Subject<boolean>();
  private _lightTheme = new Subject<boolean>();

  isDarkTheme = this._darkTheme.asObservable();
  isLightTheme = this._lightTheme.asObservable();
  isDarkThemeVariableSet = false;
  imgPath = '../../../../../../../assets/icons_light/';
  imgLogo = 'NIFLOGO_light.svg';

  get getDarkTheme(): Observable<any> {
    return this.isDarkTheme;
  }
  constructor(private rendererFactory: RendererFactory2,
    private httpService: HttpService,
    @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.renderer.addClass(this.document.body, 'light-theme');
    this.isDarkThemeVariableSet = false;
  }
  setDarkTheme(isDarkTheme: boolean): void {

    if (isDarkTheme) {
      this.imgPath = '../../../../../../../assets/icons/';
      this.imgLogo = 'NIFLOGO.svg';
      this.renderer.removeClass(document.body, 'light-theme');
      this.renderer.addClass(document.body, 'dark-theme');
      this.isDarkThemeVariableSet = true;

    } else {
      this.imgLogo = 'NIFLOGO_light.svg';
      this.imgPath = '../../../../../../../assets/icons_light/';
      this.renderer.removeClass(document.body, 'dark-theme');
      this.renderer.addClass(document.body, 'light-theme');
      this.isDarkThemeVariableSet = false;

    }
    this._darkTheme.next(isDarkTheme);
  }
  setLightTheme(isLightTheme: boolean): void {
    this._lightTheme.next(isLightTheme);
  }

  GetCurrentThemeId() {
    const themeId = localStorage.getItem(GlobalConstants.CookieKeys.ThemeId);
    if (themeId) {
      return JSON.parse(themeId);
    } else {
      return ThemeEnum.LightTheme;
    }
  }

  setTheme(themeId) {
    localStorage.setItem(GlobalConstants.CookieKeys.ThemeId, JSON.stringify(themeId));
    const isDarkTheme = themeId === ThemeEnum.DarkTheme ? true : false;
    this.setDarkTheme(isDarkTheme);
    this.setLightTheme(!isDarkTheme);
  }


  updateUserTheme(themeId: number) {
    const queryString: any = [];
    queryString.push('themeId=' + themeId);
    const apiPath = `${GlobalConstants.ApiPath.User.Theme}?${queryString.join('&')}`;
    return this.httpService.put<ResponseModel>(apiPath);
  }

}
