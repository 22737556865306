import { Directive, ElementRef, Renderer2, Input, OnInit, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[addRemoveClass]'
})
export class AddRemoveClassDirective implements AfterViewInit {

    @Input('addRemoveClass') addRemoveClass: string;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

    ngAfterViewInit() {
        this.setAndRemoveClass(this.addRemoveClass);
    }

    setAndRemoveClass(className: string) {
        this.renderer.addClass(this.elementRef.nativeElement, className);
        setTimeout(() => {
            this.renderer.removeClass(this.elementRef.nativeElement, className);
        }, 1000);
    }
}