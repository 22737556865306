export const LanguagesEnum = [
    {
        'id': 'no',
        'title': 'Norwegian',
        'flag': 'no',
        'ISO': 'nb-NO'
    },
    {
        'id': 'en',
        'title': 'English',
        'flag': 'us',
        'ISO': 'en-GB'
    },
];
