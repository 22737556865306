import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@det/services/helper.service';
import { AlertModel } from '@det/core/common.model';
import { MessageMapping } from './error-parse';
import { GlobalConstants } from '@det/core/global-constants';

@Injectable()
export class ErroHandlerService {

    constructor( 
    private helperService: HelperService,
    private translateService: TranslateService,
  ) { }

  parseAndDisplayAlert(error, alertConfig, moduleName, callback = null, showButton = false) {
      alertConfig = new AlertModel(alertConfig);
      let msg = '', noMessageAvailable = false;
      if (error && error.ErrorCode && moduleName && MessageMapping[moduleName]) {
        msg = MessageMapping[moduleName][error.ErrorCode];
      } else if (error &&  error.ErrorCode === 0 ) {
        msg = 'COMMON.UnableToConnectInternet';
      }
      if (!msg) {
        noMessageAvailable = true;
        msg = 'COMMON.SomethingWentWrong';
        alertConfig.duration = GlobalConstants.alertDuration;
      } 

      const translatedMessage = this.translateService.instant(msg);
      const alertData = new AlertModel({
          message: translatedMessage,
          duration: alertConfig.duration 
      });

      if ((noMessageAvailable || showButton) && alertConfig.buttonText && callback) {
          alertData.buttonText = this.translateService.instant(alertConfig.buttonText);
          this.helperService.displayAlert(alertData, () => {
              callback();
          });
       } else {
          this.helperService.displayAlert(alertData);
       }
    }

}

