import { ContentComponent } from './content.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common'; 

@NgModule({
    declarations: [
        ContentComponent,
    ],
    imports: [
        RouterModule,
        FlexLayoutModule,
        InfiniteScrollModule,
        MatSidenavModule,
        MatMenuModule,
        MatIconModule,
        TranslateModule.forChild(),
        CommonModule
    ],
    exports: [
        ContentComponent,
    ]
})
export class ContentModule {
}
