import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
@Directive({
  selector: '[appLoaderDirectorForIcon]'
})
export class LoaderDirectorForIconDirective implements OnInit {

  @Input() set isloading(shouldAdd: boolean) {
    const spanElement = this.elementRef.nativeElement.querySelector('span');
    if (shouldAdd) {
      this.renderer.addClass(this.elementRef.nativeElement, 'spinner');
      this.renderer.addClass(spanElement, 'opacity-zero');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'spinner');
      this.renderer.removeClass(spanElement, 'opacity-zero');
    }
  }
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit() {
    if (this.isloading) {
      this.renderer.addClass(this.elementRef.nativeElement, 'spinner');
      const spanElement = this.elementRef.nativeElement.querySelector('span');
      this.renderer.addClass(spanElement, 'opacity-zero');
    }
  }
}

