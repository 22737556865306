import { ApiError } from '@det/core/api-error-model';
import jwt_decode from "jwt-decode";
import { TranslationLoaderService } from './../services/translation-loader.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SubscriptionLike as ISubscription, Observable } from 'rxjs';
import { GlobalConstants } from './global-constants';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as norwegian } from '../../app/main/content/auth/i18n/no';
import { locale as english } from '../../app/main/content/auth/i18n/en';

import Swal from 'sweetalert2';
import { VerifyLoggedInUserModel, VerifyLoggedInUserResponseModel } from 'app/main/content/auth/sign-in/login-model';
@Injectable()
export class AnonymousGuardGuard implements CanActivate {
  languages: any;
  selectedLanguage: any;
  VerifyLoggedInUserSubscriptions: ISubscription;
  constructor(private loginService: AuthService, private router: Router, private translateService: TranslateService,
    private translationLoader: TranslationLoaderService) {
    this.languages = GlobalConstants.Languages;
    this.translationLoader.loadTranslations(english, norwegian);
    this.selectedLanguage = this.languages[0];
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(res => {
      const isFromMail = next.data && next.data.loginViaMail ? true : false;
      const storedToken = localStorage.getItem(GlobalConstants.LocalStorageData.oAuthToken);
      if (isFromMail && storedToken) {
        const base64TokenFromURL = next.paramMap.get('type');
        const tokenFromURL = atob(base64TokenFromURL);
        const decodedHeader = jwt_decode(tokenFromURL, { header: true });
        const storedDecoded = jwt_decode(storedToken);
        const storedBuyPassID = storedDecoded['buypassid'];
        const emailNIFPersonID = decodedHeader['NIFPersonId'];
        if (emailNIFPersonID) {
          // call web 
          //        Person id --  BuyPassId
          //carl    9283880   --  
          //ine     9284190   --  101128536
          const temp: VerifyLoggedInUserModel = {
            InvitationEmailPersonId: Number(emailNIFPersonID),
            LoggedInUserBuyPassId: Number(storedBuyPassID)
          };
          if (this.VerifyLoggedInUserSubscriptions !== undefined) {
            this.VerifyLoggedInUserSubscriptions.unsubscribe();
          }
          this.VerifyLoggedInUserSubscriptions = this.loginService.VerifyLoggedInUser(temp)
            .subscribe((response: VerifyLoggedInUserResponseModel) => {
              if (response) {
                if (response.IsValidUser) {
                  this.successResponse();
                  res(true);
                }
                else {
                  this.errorForVerifiedUser();
                  res(true);
                }
              }
              else {
                this.errorForVerifiedUser();
                res(true);
              }
            }, (err: ApiError) => {
              this.errorForVerifiedUser();
              res(true);
            });
        }
        else {
          this.errorForVerifiedUser();
          res(true);
        }
      }
      else {
        this.successResponse();
        res(true);
      }
    });
  }
  successResponse() {
    const isLoggedIn: boolean = this.loginService.isLoggedIn;
    const isAllowToAccess: boolean = this.loginService.allowToAccess;
    if (isLoggedIn) {
      this.router.navigate([GlobalConstants.Routes.Dashboard]);
    } else if (isAllowToAccess) {
      this.router.navigate([GlobalConstants.Routes.auth + '/' + GlobalConstants.Routes.PostLogin]);
    }
    return true;
  }
  errorForVerifiedUser() {
    Swal.fire({
      title: '',
      text: this.translateService.instant('InValidLogin'),
      icon: 'error',
    }).then((result) => {
      this.router.navigateByUrl('/' + GlobalConstants.Routes.auth + '/' + GlobalConstants.Routes.SignOut + '/force');
    });
    return true;
  }
}
