import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { ApiError } from '@det/core/api-error-model';
import { UserSettingModel, UserInfoModel } from 'app/main/content/auth/sign-in/login-model';
import { AuthService } from '@det/core/auth.service';
import { GlobalAPIConstants } from "@det/core/global-api-constants";
import { GlobalConstants } from '@det/core/global-constants';
import { LoginUserModel } from '@det/core/user-model';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '@det/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as norwegian } from './i18n/no';
import { UserType } from '@det/core/common-enums';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { ThemeEnum } from '@det/core/common-enums';
import { ThemeService } from '@det/services/theme.service';
import { CustomCookieService } from '@det/core/cookie.service';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    UserData: LoginUserModel;
    userProfilePic = 'assets/images/avatars/profile.jpg';
    showUserImage = false;
    fullUserName = '';
    UserType = UserType;

    isDarkTheme: Observable<boolean>;
    darkThemeFlag = false;
    currentTheme = 1;
    applyingDarkTheme = false;
    applyingLightTheme = false;
    languages: any;
    CurrentLang = '';
    selectedLanguage: any;
    UserInfo: UserInfoModel;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    SelectedProUserID = '';
    isDarkThemeSubscribe: ISubscription;
    FederationName = '';
    constructor(
        private translate: TranslateService,
        private translationLoader: TranslationLoaderService,
        private authService: AuthService,
        private customCookieService: CustomCookieService,
        private router: Router,
        private themeService: ThemeService
        ) {
        this.languages = GlobalConstants.Languages;
        this.translationLoader.loadTranslations(english, norwegian);
        this.selectedLanguage = this.languages[0];
        this.getSelectedLang();
        this.darkThemeFlag = this.themeService.isDarkThemeVariableSet;
        if (this.authService.isLoggedIn) {
            this.UserData = this.authService.getUserInfo();
            if (this.UserData.Medias !== undefined && this.UserData.Medias.length > 0 && this.UserData.Medias[0] !== undefined) {
                this.userProfilePic = (this.UserData.Medias[0].MediaPath !== '') ? this.UserData.Medias[0].MediaPath : this.userProfilePic;
                this.showUserImage = true;
            } else {
                this.showUserImage = false;
            }
            this.fullUserName = this.UserData.FirstName + ' ' + this.UserData.LastName;
        }

        router.events.subscribe(
            (event) => {
                if (event instanceof NavigationEnd) {
                    if (this.authService.isLoggedIn) {
                        const selectedProUser = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.LastSelectedProUser));
                        this.SelectedProUserID = selectedProUser['SelectedProUserID'];
                   
                        const userInfo = this.authService.getUserInfo();
                        if (userInfo && userInfo.BusinessUnitName) {
                            this.FederationName = userInfo.BusinessUnitName;
                        }
                        
                    }

                }
            });
        
    }

    ngOnInit() {
        this.UserInfo = this.authService.getFullUserInfo();
        this.isDarkThemeSubscribe = this.themeService.getDarkTheme.subscribe(state => {
            this.darkThemeFlag = this.themeService.isDarkThemeVariableSet;
        });
        if (this.authService.isLoggedIn) {
            const selectedProUser = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.LastSelectedProUser));
            this.SelectedProUserID = selectedProUser['SelectedProUserID'];
            const userInfo = this.authService.getUserInfo();
            if (userInfo && userInfo.BusinessUnitName) {
                this.FederationName = userInfo.BusinessUnitName;
            }
        }
    }

    toggleDarkTheme(theme) {
        this.currentTheme = theme;

        if (theme === ThemeEnum.DarkTheme) {
            this.applyingDarkTheme = true;
            this.applyingLightTheme = false;
        } else {
            this.applyingDarkTheme = false;
            this.applyingLightTheme = true;
        }


        this.themeService.updateUserTheme(this.currentTheme)
            .subscribe((response: any) => {
                this.themeService.setTheme(this.currentTheme);
                this.darkThemeFlag = this.themeService.isDarkThemeVariableSet;
            }, (err: ApiError) => {
                this.isDarkTheme = this.themeService.isDarkTheme;
                this.darkThemeFlag = this.themeService.isDarkThemeVariableSet;
            });

        setTimeout(() => {
            this.themeService.setTheme(this.currentTheme);
            this.applyingDarkTheme = false;
            this.applyingLightTheme = false;
            this.trigger.closeMenu();
        }, 2000);

    }
    mailToHelpDesk() {
        const mailText = "mailto: imssupport@idrettsforbundet.no?subject=&body=";
        window.location.href = mailText;
    }

    logout() {
        this.router.navigateByUrl('/' + GlobalConstants.Routes.auth + '/' + GlobalConstants.Routes.SignOut + '/force');
    }
    
    switchLang(langCode) {
        this.CurrentLang = langCode;
        this.translate.setDefaultLang(langCode);
        this.translate.use(langCode);
        this.customCookieService.deleteCookie(GlobalConstants.CookieKeys.Language);
        this.customCookieService.createCookie(GlobalConstants.CookieKeys.Language, langCode, 180000);
        this.updateUserSetting(GlobalConstants.UserSetting.Language, langCode);
    }

    updateUserSetting(key, data) {
        const settings: UserSettingModel = new UserSettingModel({});
        settings.UserSettingKey = key;
        settings.UserSettingData = data;
        this.authService.UpdateUserSetting(settings).subscribe(Response => {
        }, err => {
        });
    }
    
    getSelectedLang() {
        const userLanguage = this.customCookieService.getCookie(GlobalConstants.CookieKeys.Language);
        if (userLanguage === 'en-GB') {
            this.selectedLanguage = this.languages[1];
        } else {
            this.selectedLanguage = this.languages[0];
        }
        this.CurrentLang = this.selectedLanguage.ISO;
        this.translate.setDefaultLang(this.selectedLanguage.ISO);
        this.translate.use(this.selectedLanguage.ISO);
    }
    reloadPage(){
        window.location.reload();
    }
    ProceedToNext(ProUserID) {
        this.router.navigateByUrl('/' + GlobalConstants.Routes.auth + '/' + GlobalConstants.Routes.PostLogin + '/' + ProUserID);
    }

    ngOnDestroy() {
        if (this.isDarkThemeSubscribe !== undefined) {
            this.isDarkThemeSubscribe.unsubscribe();
        }
    }
}
