import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { DirectivesModule } from './directives/directives';
import { PipesModule } from './pipes/pipes.module';
import { MaterialModule } from './angular-material/material-module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        DirectivesModule,
        PipesModule,
        MaterialModule

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        DirectivesModule,
        PipesModule,
        MaterialModule

    ],
    providers: []
})
export class SharedModule {
}
