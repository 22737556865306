import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ThemeService } from '@det/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Federation-CRM';
  isDarkTheme: Observable<boolean>;
  isLightTheme: Observable<boolean>;

  constructor(private themeService: ThemeService) {

  }

  ngOnInit() {
    this.isDarkTheme = this.themeService.isDarkTheme;
    this.isLightTheme = this.themeService.isLightTheme;

    setTimeout(() => {
      const themeId = this.themeService.GetCurrentThemeId();
      this.themeService.setTheme(themeId);
    }, 2000);
  }
}
