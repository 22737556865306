import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  isMenuOpen = false;
  showFabButton = false;

  constructor(private router: Router) {
    
    router.events.subscribe(
      (event) => {
          if (event instanceof NavigationEnd) {
            if ((this.router.url.indexOf('/auth/') === -1) &&
            (this.router.url.indexOf('/new') === -1) &&
            (this.router.url.indexOf('/edit') === -1)) {
              this.showFabButton = true;
            } else {
              this.showFabButton = false;
            }
          }
      });
   }

  ngOnInit(): void {
  }

}
