import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthService } from '@det/core/auth.service';
import * as moment from 'moment';
import { ShortNamePipe } from '@det/pipes/short-name.pipe';
import { MembershipStatusEnum } from '@det/core/common-enums';

@Injectable()
export class CommonService {
    private cartSubject = new BehaviorSubject('default');
    getAdminFeeSubscribe: Subscription;
    CurrentCart = this.cartSubject.asObservable();

    private WishListSubject = new BehaviorSubject('default');
    CurrentWishList = this.WishListSubject.asObservable();

    
  constructor(private authService: AuthService,
    private shortNamePipe: ShortNamePipe,
    ) { }

    updateWishList() {
        this.WishListSubject.next('true');
    }

    updateCartList() {
        this.cartSubject.next('true');
    }
        
    addDateLabel(data) {
        if (data && data.length) {
        data.forEach( (team) => {
        
            if (team.startDate) {
            const isAfter = moment(team.startDate).isAfter(new Date());
            if (isAfter) {
                team.startDatelabel = 'TEAMS.StartsOn';
            } else {
                team.startDatelabel = 'TEAMS.StartedOn';
            }
            } else {
            team.startDatelabel = 'TEAMS.StartDate';
            }

            if (team.endDate) {
            const isAfterEndDate = moment(team.endDate).isAfter(new Date());

            if (isAfterEndDate) {
                team.endDatelabel = 'TEAMS.EndsOn';
            } else {
                team.endDatelabel = 'TEAMS.EndedOn';
            }
            } else {
            team.endDatelabel = 'TEAMS.EndDate';
            }

        });
        }

    }
   
   numberFromText(text) {
    // numberFromText("AA");
    const charCodes = text
      .split('') // => ["A", "A"]
      .map(char => char.charCodeAt(0)) // => [65, 65]
      .join(''); // => "6565"
    return parseInt(charCodes, 10);
  }

  setUserNameInvoiceColor(list) {
    const colors = ['blue', 'green', 'orange', 'red'];
    list.map( (member, i) => {
      let FullName = '';
      if (member.FirstName) {
        FullName = member.FirstName.split(" ")[0];
      }
      if (member.LastName) {
        FullName = FullName + ' ' + member.LastName.split(" ")[0];
      }
      member.shortName = this.shortNamePipe.transform(FullName);
    //   member.BgColorClass = colors[this.numberFromText(shortName) % colors.length];
      // member.BgColorClass = colors[i % colors.length];
      if (member.clubMembershipStatus === MembershipStatusEnum.Active || member.clubMembershipStatus === MembershipStatusEnum.Approved || 
          member.clubMembershipStatus === MembershipStatusEnum.CancelRequest) {
        member.StatusColorClass = 'green';
      } else if (member.clubMembershipStatus === MembershipStatusEnum.Pending) { 
        member.StatusColorClass = 'orange';
      } else if (member.clubMembershipStatus === MembershipStatusEnum.SentForApproval) {
        member.StatusColorClass = 'purple';
      } else if (member.clubMembershipStatus === MembershipStatusEnum.Expired || member.clubMembershipStatus === MembershipStatusEnum.Cancelled) {
        member.StatusColorClass = 'red';
      }

    });
  }

}