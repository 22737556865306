import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@det/animations';
import { TranslationLoaderService } from '@det/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertEnum } from '@det/core/common-enums';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ThemeService } from '@det/services/theme.service';
import { locale as norwegian } from '../../i18n/no';
import { locale as english } from '../../i18n/en';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: fuseAnimations
})
export class AlertComponent implements OnInit, OnDestroy {
  message = '';
  buttonText = '';
  type = AlertEnum.Error;
  title = '';
  displayCloseButton = true;
  imageFolder = 'icons_light';
  icon = '../../../assets/' + this.imageFolder + '/custom/alert/InfoIcon.svg';
  closeIcon = '../../../assets/' + this.imageFolder + '/custom/alert/CloseC.svg';
  colorClass = '';
  isDarkThemeSubscribe: ISubscription;

  constructor(
    public snackBarRef: MatSnackBarRef<AlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private translationLoader: TranslationLoaderService,
    private translateService: TranslateService,
    private themeService: ThemeService
  ) {
    this.translationLoader.loadTranslations(english, norwegian);
    this.imageFolder = this.themeService.isDarkThemeVariableSet ? 'icons' : 'icons_light';
    this.icon = '../../../assets/' + this.imageFolder + '/custom/alert/InfoIcon.svg';
    this.closeIcon = '../../../assets/' + this.imageFolder + '/custom/alert/CloseC.svg';
    if (data && data.message) {
      this.message = data.message;
    }
    if (data && data.buttonText) {
      this.buttonText = data.buttonText;
    }
    if (data && data.type) {
      this.type = data.type;
    }
    if (data && data.withoutClose) {
      this.displayCloseButton = !data.withoutClose;
    } else {
      this.displayCloseButton = true;
    }
  }

  ngOnInit() {
    this.isDarkThemeSubscribe = this.themeService.getDarkTheme.subscribe(state => {
      if (!state) {
        this.imageFolder = 'icons_light';
      } else {
        this.imageFolder = 'icons';
      }
      this.closeIcon = '../../../assets/' + this.imageFolder + '/custom/alert/CloseC.svg';
      this.setTypeTitle();
    });
    this.setTypeTitle();
  }

  private setTypeTitle() {
    switch (this.type) {
      case AlertEnum.Success:
        this.title = this.translateService.instant('SuccessTitle');
        this.colorClass = 'success';
        this.icon = '../../../assets/' + this.imageFolder + '/custom/alert/SuccessIcon.svg';
        break;
      case AlertEnum.Error:
        this.title = this.translateService.instant('ErrorTitle');
        this.colorClass = 'error';
        this.icon = '../../../assets/' + this.imageFolder + '/custom/alert/ErrorIcon.svg';
        break;
      case AlertEnum.Info:
        this.title = this.translateService.instant('InfoTitle');
        this.colorClass = 'info';
        this.icon = '../../../assets/' + this.imageFolder + '/custom/alert/InfoIcon.svg';
        break;
      case AlertEnum.Warning:
        this.title = this.translateService.instant('WarningTitle');
        this.colorClass = 'warning';
        this.icon = '../../../assets/' + this.imageFolder + '/custom/alert/WarningIcon.svg';
        break;
    }
  }

  onButtonClick() {
    this.snackBarRef.dismissWithAction();
  }

  onClose() {
    this.snackBarRef.dismiss();
  }

  ngOnDestroy() {
    if (this.isDarkThemeSubscribe !== undefined) {
      this.isDarkThemeSubscribe.unsubscribe();
    }
  }
}

