export const locale = {
    lang: 'en-GB',
    data: {
        'COMMON': {
            'PortalName': 'NIF',
            'Filter': 'Filter',
            'ClearAll': 'Clear All',
            'ADDNEW': 'Add New',
            'ClearFilter': 'Clear',
            'Edit': 'Edit',
            'View': 'View',
            'Ok': 'Ok',
            'UnableToFetch': 'Unable to fetch data from server. Please try again.',
            'ComingSoon': 'Coming soon',
            'Showing': 'Showing',
            'of': 'of',
            'Cancel': 'Cancel',
            'SomethingWentWrong': 'Something went wrong. Please try again.',
            'Language': 'Language',
            'Norwegian': 'Norwegian',
            'English': 'English',
            'Theme': 'Theme',
            'Dark': 'Dark',
            'Light': 'Light',
            'Federations': 'Federations',
            'Logout': 'Logout',

        },
        'MEMBERSHIP': {
            'PageTitle': 'Members',
            'Gender': 'Gender',
            'Unknown': 'Unknown',
            'Male': 'Male',
            'Female': 'Female',
            'Name': 'Name',
            'Active': 'Active',
            'All': 'All',
            'Pending': 'Pending verification',
            'NotAutoRenew': 'Not automatically renewed',
            'Cancelled': 'Cancelled',
            'AgeingOut': 'Ageing out',
            'Membership': 'Membership',
            'GroupArea': 'Area',
            'Function': 'Function',
            'County': 'Region',
            'PendingApproval': 'Pending Approval',
            'CancellationRequested': 'Cancellation requested',
            'NewInLastWeek': 'New In Last Week',
            'ShownInterest': 'Shown Interest',
            'AgingOut': 'Aging Out',
            'Automaticallyrenewed': 'Automatically renewed',
            'NotAutomaticallyRenewed': 'Not Automatically Renewed',
            'Members': 'Members',
            'ClearFilter': 'Clear filter',
            'DigitallyActive': 'Digitally active',
            'ActiveRole': 'Active Role',
            'ActivityParticipated': 'Activity Participated',
            'Memberships': 'Memberships',
            'GenderAndAge': 'Gender & Age',
            'Total': 'All',
            'MembershipInvoiceStatus': 'Membership Invoice Status',
            'TrainingFeeInvoiceStatus': 'Training Fee Invoice Status',
            'TeamInvoiceStatus': 'Team Participation Invoice Status',
            'Paid': 'Paid',
            'Overdue': 'Overdue',
            'NotInvoiced': 'Not invoiced',
            'Invoiced': 'Invoiced',
            'Unpaid': 'Unpaid',
            'MembershipInvoice': 'Membership',
            'TrainingFeeInvoice': 'Training fee',
            'TeamInvoice': 'Team participation',
            'TeamMembershipStatus': 'Team Membership Status',
            'Waiting': 'Waiting',
            'InTeam': 'In Team',
            'RemovedFromTeam': 'Removed from Team',
            'LeftTeam': 'Left Team',
            'SportAndStyle': 'Sport & Style',
            'ClubLocation': 'Club',
            'MemberLocation': 'Member Location',
            'NotActive': 'Not Active',
            'SentForApproval': 'Awaiting Approval',
            'SearchByNameOrrPersonID': 'Search by Name or Person ID',
            'InviteToIMS': 'Communication',
            'SendInvitationLink': 'Send invitation link',
            'SendEmail': 'Send email',
            'SendSMS': 'Send SMS',
            'Export': 'Export',
            'ExcelFile': 'Excel file',
            'CSVFile': 'CSV file',
            'PDFFile': 'PDF file',
            'PersonID': 'Person ID',
            'SportsNumber': 'Sports Number',
            'Age': 'Age',
            'Phone': 'Phone',
            'Email': 'Email',
            'Location': 'Location',
            'ClubName': 'Club Name',
            'MembershipInvoiceHeader': 'Membership Invoice',
            'MembershipNextInvoiceDate': 'Membership next invoice date',
            'Sport': 'Sport',
            'TrainingFeeInvoiceHeader': 'Training Fee Invoice',
            'TrainingFeeNextInvoiceDate': 'Training fee next invoice date',
            'TeamParticipation': 'Team Participation',
            'TeamParticipationStatus': 'Team Participation Status',
            'TeamParticipationInvoice': 'Team Participation Invoice',
            'InvitationLinkHasBeenSend': 'Invitation link has been sent',
            'Expired': 'Expired',
            'GroupFunctionTypeName': 'Group Function Type Name',
            'GroupMembershipStatus': 'Group Membership Status',
            'GroupName': 'Group Name',
            'GroupCounty': 'Group County',
            'GroupAreaName': 'Group Area Name',
            'GroupCouncil': 'Group Council',
            "East": "East",
            "West": "West",
            "South": "South",
            "Middle": "Middle",
            "North": "North",
        },
        'COMMUNICATION': {
            'TotalReceiver': 'Total receiver',
            'Message': 'Message',
            'SendSMS': 'Send SMS',
            'SendEmail': 'Send Email',
            'BulkSms': 'Bulk SMS',
            'BulkEmail': 'Bulk email',
            'Subject': 'Subject',
            'MessageIsRequired': 'Message is required',
            'SubjectIsRequired': 'Subject is required',
            'BulkSmsSentSuccessfully': 'Bulk SMS sent successfully',
            'BulkEmailSentSuccessfully': 'Bulk email sent successfully',
            'InvitationSentSuccessfully': 'Invitation sent',
            'EmailBodyRequired': 'Email body is required',
            'EmailBody': 'Email body',
            'NumberIsRequired': 'Phone number is required',
            'SendInvitation': 'Invite',
            'InvalidPhoneNumbers': 'Please provide a valid phone number to send the invitation',
            'InvalidEmails': 'Please provide a valid email address to send the invitation',
            'ExampleForEmail': ' eg: abc@abc.com,xyz@abc.com',
            'ExampleForPhone': ' eg: 123456789,568784125',
            'InviteMultipleMember': 'Invite multiple people',
            'TextForSingleInvite': 'You can invite people to sign up for memberships for themselves by providing their email address or phone number. A link will be sent to them from where they can onboard.',
            'NoteForEitherPhoneOrEmailIsMandotary': 'You can add email address or phone number, or both to send invitation link.',
            'CountryCode': 'Ext.',
            'or': 'OR',
            'TooltipInfo': 'One or more recipients may have the same contact information. The message will be delivered only once to the same contact information.',
            'SuccessMessageSMS': 'The SMS has been successfully sent to {{Count}}',
            'SuccessMessageEmail': 'The email has been successfully sent to {{Count}}'
        },
    }
};



