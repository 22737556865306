import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    transform(value: any, args: any = []): any {
        const keys: any[] = [];

        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                keys.push({
                    key: key,
                    value: value[key]
                });
            }
        }

        return keys;
    }
}


@Pipe({ name: 'keysPipe' })
export class KeysPipeFilter implements PipeTransform {
    transform(value: any, args: string[] = []): any {
        let keys: any[] = [];

        for (const key in value) {
            if ((value.hasOwnProperty(key)) && (Number(key) || key === '0')) {
                keys.push({
                    key: key,
                    value: value[key]
                });
            }
        }

        if (args !== undefined && args.length > 0) {
            keys = keys.filter(data => data.key.toString() !== args[0].toString());
        }

        return keys;
    }
}
