<mat-toolbar>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="toolbar-content">
        <div class="app-logo" fxLayout="row" fxLayoutAlign="start center" (click)="reloadPage()">
            <img src="../../../assets/images/logos/NIFLOGO_toolbar_light.svg" alt="logo" width="90px" />
            <div class="FederationName">{{FederationName}}</div>
        </div>

        <div class="right-content" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button [matMenuTriggerFor]="languageMenu" class="user-menu" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>settings</mat-icon>
                      </button>
            <mat-menu #languageMenu="matMenu" backdropClass="language-menu">
                <div class="menu-title value-color">{{'COMMON.Language' | translate}}</div>
                <button mat-menu-item class="language-menu-item" [ngClass]="{'selected': CurrentLang == 'nb-NO'}" (click)="switchLang('nb-NO')">
                  <div class="language-title value-color">{{'COMMON.Norwegian' | translate}}</div>
                  <!-- <div class="language-text key-color">Alt innhold er tilgjengelig på bokmål</div> -->
                </button>
                <button mat-menu-item class="language-menu-item" [ngClass]="{'selected': CurrentLang == 'en-GB'}" (click)="switchLang('en-GB')">
                    <div class="language-title value-color">{{'COMMON.English' | translate}}</div>
                    <!-- <div class="language-text key-color">Some content are available in English</div> -->
                  </button>
                <div class="seperator"></div>
                <div class="menu-title value-color">{{'COMMON.Theme' | translate}}</div>
                <button mat-menu-item class="language-menu-item" [class.selected]="!darkThemeFlag" (click)="toggleDarkTheme(1);$event.stopPropagation();$event.preventDefault();">
                  <div fxLayout="row"   fxLayoutAlign="space-between center">
                  <div class="language-title value-color">{{'COMMON.Light' | translate}}</div>
                  <mat-spinner diameter="20" mode="indeterminate" *ngIf="applyingLightTheme"></mat-spinner>
                </div>
                  </button>
                <button mat-menu-item class="language-menu-item" [class.selected]="darkThemeFlag" (click)="toggleDarkTheme(2);$event.stopPropagation();$event.preventDefault();">
                  <div fxLayout="row"   fxLayoutAlign="space-between center">
                    <div class="language-title value-color">{{'COMMON.Dark' | translate}}</div>
                    <mat-spinner diameter="20" mode="indeterminate" *ngIf="applyingDarkTheme"></mat-spinner>
                  </div>
                    </button>
            </mat-menu>
            <div class="seperator"></div>
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-menu" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="row" fxLayoutAlign="center center" class="user-info">
                <img alt="avatar" class="avatar" [src]="userProfilePic" *ngIf="showUserImage">
                <div class="initials" *ngIf="!showUserImage">{{fullUserName | shortName}}</div>
                <div fxLayout="column" fxLayoutAlign="center start" fxHide fxShow.gt-xs>
                  <span class="username ">{{UserData | nameBuilder}}</span>
                </div>

              </div>
            </button>
            <mat-menu #userMenu="matMenu" backdropClass="language-menu">
                <div class="menu-title value-color">{{'COMMON.Federations' | translate}}</div>
                <ng-container *ngFor="let proUser of UserInfo.ProUsers">
                    <button mat-menu-item class="language-menu-item" (click)="ProceedToNext(proUser.ProUserID)" [ngClass]="{'selected': SelectedProUserID === proUser.ProUserID }">
                      <div class="language-title value-color" matTooltip="{{proUser.BusinessUnitName}}">{{proUser.BusinessUnitName}}</div>
                  </button>
                </ng-container>
                <!-- <button mat-menu-item class="language-menu-item">
                    <div class="language-title value-color" >Norges Gymnastikk- og Turnforbund</div>
                  </button>
                <button mat-menu-item class="language-menu-item">
                      <div class="language-title value-color" >Norges Kampsportforbund</div>
                    </button> -->
                <div class="seperator"></div>
                <button mat-menu-item class="language-menu-item" (click)="logout()">
                    <div class="language-title value-color">{{'COMMON.Logout' | translate}}</div>
                  </button>
            </mat-menu>
            <mat-icon class="help-icon" (click)="mailToHelpDesk()">help_outline</mat-icon>
        </div>
    </div>
</mat-toolbar>