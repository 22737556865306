import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'generateFourDecimalAndReplaceDotToComa' })
export class GenerateFourDecimalAndReplaceDotToComaPipe implements PipeTransform {
    transform(value: number): any {
        const defaultValue = 0;
        if (isNaN(value)) {
            return "-";
        }
        if (value !== undefined && value !== null) {
            return "kr " + value.toFixed(2).replace('.', ',');
        } else {
            return "kr " + defaultValue;
        }
    }
}
