import { Pipe, PipeTransform } from '@angular/core';
import { MediaType } from '../core/common-enums';

@Pipe({ name: 'initials' })
export class InitialsPipe implements PipeTransform {
    transform(value: string, args: string[]): any {
        const initials = value.match(/\b\w/g) || [];
        const nameInitials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return nameInitials;
    }
}
