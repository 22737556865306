import { ApiPath } from './api';
import { Component } from '@angular/core';
import { LanguagesEnum } from './language';
import { Routes } from './route.URL';
import { environment } from 'environments/environment';

export const GlobalConstants = {
    SuperAdminRbacRoleID: 'f7d58c71-2b80-4e31-bc2d-1ef765b7b6dc',
    DefaultBusinessUnitID: 'E8B14669-BC01-4D8F-A457-3217749880B8',
    CookieKeys: {
        AuthKey: 'NIFB2C-authLoginInfo',
        ModuleList: 'NIF-ModuleList',
        Token: 'NIFB2C-loginToken',
        OldToken: 'NIFB2C-oldToken',
        ConsumerToken: 'NIFB2C-ConsumerToken',
        Language: 'NIFB2C-Lang',
        LocalConfig: 'NIFB2C-Config',
        PreferenceConfig: 'NIFB2C-Preference-Config',
        HistoryData: 'NIF-History',
        GeoData: 'NIFB2C-GeoData',
        RememberMe: 'NIFB2C-RememberMe',
        CompleteUserInfo: 'NIF-CompleteUserInfo',
        CurrencyData: 'NIFB2C-CurrencyList',
        IsProUser: 'NIF-ProUser',
        TotalMediaUploading: 'NIFB2C-TotalMediaUploading',
        CartData: "NIFB2C-CartData",
        ContractData: "NIFB2C-ContractData",
        IndividualMembership: "NIFB2C-IndividualMembership",
        LastSelectedProUser: 'NIF-LastSelectedProUser',
        LastSelectedMembership: "NIFB2C-LastSelectedMembership",
        ConsumerProfileID: "NIFB2C-ConsumerProfileID",
        ContactID: "NIFB2C-ContactID",
        NETSTransactionID: "NIFB2C-TransactionID",
        AccountLogo: 'NIF-ClubLogo',
        AfterPaymentPath: "NIFB2C-AfterPaymentPath",
        IsDepartmentVisibleForClubUser: "NIF-IsDepartmentVisible",
        NETSDraftOrderResponse: "NIFB2C-DraftOrderResponse",
        MembershipPaymentType: "NIFB2C-MembershipPaymentType",
        ClubDetail: "NIFB2C-ClubDetail",
        EnvConfig: "NIF-b2b-env",
        BusinessLogo: 'NIF-FederationLogo',
        IndividualMembershipID: 'membershipID',
        IsNIFPayment: 'NIFPayment',
        StatusKey: 'statusKey',
        KID: 'KID',
        OrderID: 'orderID',
        IsMerchantSet: 'IsMerchantSet',
        IsOnboardingUser: 'IsOnboardingUser',
        OnBoardingClubId: 'OnBoardingClubId',
        OnBoardingPersonId: 'OnBoardingPersonId',
        ThemeId: 'ThemeId',
        IsFromAcquireIMSToken: 'isFromAcquireIMSToken'
    },
    LocalStorageData: {
        oAuthToken: 'access_token',
        signInToken: 'signin_token',
        oldAquireToken: 'oldAquire_token',
        authorisationToken: 'auth_token'
    },
    UserSetting: {
        Language: 'Lang',
    },
    URLConcatPattern: '||--||',
    NETSPaymentGatewaySuccessResponse: 'OK',
    DefaultCookieExpirationInHours: 720,
    PaymentCookieExpire: 0.166667,
    URLParams: {
        PageNumber: 'pageNumber',
        SearchString: 'searchString',
        SortBy: 'sortBy'
    },
    ApiPath: ApiPath,
    Headers: {
        ContentTypes: {
            ApplicationOctetStream: {
                'Content-Type': 'application/octet-stream; charset=utf-8'
            },
            ApplicationJSON: { 'Content-Type': 'application/json' }
        }
    },
    Active: 2,
    InActive: 1,
    Routes: Routes,
    Languages: LanguagesEnum,
    ButtonText: {
        Yes: 'Yes',
        No: 'No'
    },
    TimeFormat: 'HH:mm',
    DateFormat: 'DD MMM YYYY',
    DateTimeFormat: 'DD MMM YYYY hh:mmA',
    DateTimeFormatNorway: 'DD MMM YYYY HH:mm',
    DateFormatWithDay: ' dddd , DD MMM YYYY',
    DateTimeFormatAMPM: 'hh:mm A',
    PageSize: 8,
    MaxPageSize: 1000,
    PaymentGatewayTimeOut: 1000,
    WebsiteRgex1: '^(https?:\\/\\/)([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$',
    WebsiteRgex: "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\\'\\(\\)\\*\\+,;=.]+$",
    EmailRgex1: '^[_a-zA-Z0-9-]+((\\.[_a-zA-Z0-9-]+)*|(\\+[_a-zA-Z0-9-]+)*)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,})$',
    EmailRgex2: "^[a-zA-Z0-9.!#$%&'*+/=?^_`\\-{|}~-æøåÆØÅ]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
    EmailRgex: "^([\\w\\-\\.\\+\\=\\^\\_\\æ\\ø\\å\\Æ\\Ø\\Å]+)@((\\[([0-9]{1,3}\\.){3}[0-9]{1,3}\\])|(([\\w\\-]+\\.)+)([a-zA-Z]{2,9}))$",
    PhoneRgex4: '(\\+47?\\s?|47?\\s?|0047\\s?)?(\\d{8}|\\d{4} \\d{4}|\\d{3} \\d{2} \\d{3}|\\d{2} \\d{3} \\d{3}|\\d{3} \\d{3} \\d{2}|\\d{2} \\d{2} \\d{2} \\d{2})',
    PhoneRgex3: '^\\+(47|91)([0-9]{8}|[ ]{1}[0-9]{3}[ ]{1}[0-9]{2}[ ]{1}[0-9]{3})',
    PhoneRgex2: '\\+[0-9]+',
    PhoneRgex1: '^\\+?[0-9]{6,16}$',
    PhoneRgex: '^[0-9]{6,15}$',
    REGEX_POSTAL_CODE: '^([0-9]{3,7})$',
    AlphaNumeric: '^([a-zA-Z0-9]+)$',
    OnlyPostiveNumeric: '^([0-9]+)$',
    AlphaNumericDotDashSpaceUnderscore: '^[a-zA-Z0-9\\_\\.\\-\\s]*$',
    AlphaNumericWithDashOnly: '^[a-zA-Z0-9-]+$',
    GUIDRgex: '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    UserNameRgex: '^[a-zA-Z0-9\\_\\.\\-]{3,16}$',
    OneHourTimeStamp: 3599749,
    MaxProductRangePrice: 1000,
    GoogleMapAPIKey: environment.GoogleMapAPIKey,
    defaultLatLng: { 'lat': 59.9099321, 'lng': 10.7162833 },
    MinimumAgeForAdult: 16,
    BlankGUID: '00000000-0000-0000-0000-000000000000',
    DefaultCurrency: '7c24df81-a525-4ca1-8f0a-d5633b6aa51c',
    MemberShipPayment: 'MemeberShipPayment',
    DefaultCurrencyCode: 'kr',
    AspectRatio: {
        _1_1: {
            height: 100,
            width: 100
        },
        _16_9: {
            height: 225,
            width: 400
        }
    },
    SupportedFile: {
        image: ['image/png', 'image/jpeg', 'image/bmp', 'image/jpg'],
        gif: ['image/gif'],
        audio: ['audio/mp3'],
        video: ['video/mp4'],
        MaxFileSize: 100000000,
        MaxBodySize: 1000000000,
    },
    RolesRights: {
        SortOrder: {
            CanView: '423f643c-1a85-4411-85bd-ec0d53f08583',
            CanAdd: '1778f5be-cc6b-4a75-83f6-430dda8b392b',
            CanEdit: 'df840646-3f33-4684-9e41-3e705c5b4c42',
        },
        TotalModuleActionRights: 12,
        TotalModuleUIComponents: 0,
        ActionID: {
            CanView: '423f643c-1a85-4411-85bd-ec0d53f08583',
            CanAdd: '1778f5be-cc6b-4a75-83f6-430dda8b392b',
            CanEdit: 'df840646-3f33-4684-9e41-3e705c5b4c42',
        },
        UIID: {
            CanView: '423f643c-1a85-4411-85bd-ec0d53f08583',
            CanAdd: '1778f5be-cc6b-4a75-83f6-430dda8b392b',
            CanEdit: 'df840646-3f33-4684-9e41-3e705c5b4c42',
        }
    },
    Module: {
        Account: { Name: 'Account', ID: '61984d47-3ea1-4746-8614-cc38a48df80c' },
        Beacon: { Name: 'Beacon', ID: 'bd41c99d-05f6-4e5e-b95a-640c2fc3c4a9' },
        BusinessUnit: { Name: 'BusinessUnit', ID: '0be4aa25-8b4d-4660-be16-caea3d258263' },
        BusinessCategory: { Name: 'BusinessCategory', ID: '' },
        ProductCategory: { Name: 'ProductCategory', ID: '' },
        ConsentPurpose: { Name: 'ConsentPurpose', ID: '' },
        ConsentType: { Name: 'ConsentType', ID: '' },
        ConsentLanguage: { Name: 'ConsentLanguage', ID: '' },
        Consent: { Name: 'Consent', ID: '839b47f0-de25-421a-bff9-d50bfbafe6c0' },
        RoleTemplate: { Name: 'RoleTemplate', ID: '' },
        Product: { name: 'Product', ID: '38f2ce26-1461-4c80-b90b-1762f6fb55f0' },
        Order: { name: 'Order', ID: '46ab1daf-67fa-491c-96e0-c832db032d16' },
        User: { Name: 'User', ID: 'd6839719-4245-4298-8444-58c4fa5ba106' },
        System: { Name: 'System', ID: '39e14aab-8bbc-4f52-a009-2d7b39709de6' },
        UserGroup: { Name: 'UserGroup', ID: 'd6839719-4245-4298-8444-58c4fa5ba106' }
    },
    NorwayCountryID: 'c0b272af-199f-4c33-bf61-bc51d52b684e',
    Activities: {
        CLICK_ON_DASHBOARD_MEMBER: 'CLICK_ON_DASHBOARD_MEMBER',
        CLICK_ON_OPEN_ORDERS_FROM_DASHBOARD: 'CLICK_ON_OPEN_ORDERS_FROM_DASHBOARD',
        CLICK_ON_ACTIVE_FROM_DASHBOARD: 'CLICK_ON_ACTIVE_FROM_DASHBOARD',
        CLICK_ON_ADD_NEW_ACTIVITY_FROM_DASHBOARD: 'CLICK_ON_ADD_NEW_ACTIVITY_FROM_DASHBOARD',
        CLICK_ON_UPCOMING_EVENTS: 'CLICK_ON_UPCOMING_EVENTS',
        CLICK_ON_MY_MEMBERSHIP: 'CLICK_ON_MY_MEMBERSHIP',
        CLICK_ON_CLUB_DETAILS_FROM_MY_MEMBERSHIP: 'CLICK_ON_CLUB_DETAILS_FROM_MY_MEMBERSHIP',
        CLICK_ON_CANCEL_MEMBERSHIP_FROM_MY_MEMBERSHIP: 'CLICK_ON_CANCEL_MEMBERSHIP_FROM_MY_MEMBERSHIP',
        CLICK_ON_ADD_NEW_TRAINING_FROM_MY_MEMBERSHIP: 'CLICK_ON_ADD_NEW_TRAINING_FROM_MY_MEMBERSHIP',
        CLICK_ON_BUY_NEW_MEMBERSHIP: 'CLICK_ON_BUY_NEW_MEMBERSHIP',
        SELECT_FEDERATION_FROM_BUY_NEW_MEMBERSHIP: 'SELECT_FEDERATION_FROM_BUY_NEW_MEMBERSHIP',
        SELECT_CLUB_FROM_BUY_NEW_MEMBERSHIP: 'SELECT_CLUB_FROM_BUY_NEW_MEMBERSHIP',
        SELECT_MEMBERSHIP_CATEGORY_FROM_BUY_NEW_MEMBERSHIP: 'SELECT_MEMBERSHIP_CATEGORY_FROM_BUY_NEW_MEMBERSHIP',
        SELECT_MEMBERSHIP_TYPE_FROM_BUY_NEW_MEMBERSHIP: 'SELECT_MEMBERSHIP_TYPE_FROM_BUY_NEW_MEMBERSHIP',
        SELECT_MEMBERSHIP_START_DATE_FROM_BUY_NEW_MEMBERSHIP: 'SELECT_MEMBERSHIP_START_DATE_FROM_BUY_NEW_MEMBERSHIP',
        ADD_TO_CART_FROM_BUY_NEW_MEMBERSHIP: 'ADD_TO_CART_FROM_BUY_NEW_MEMBERSHIP',
        ADD_TRAINING_FROM_BUY_NEW_MEMBERSHIP: 'ADD_TRAINING_FROM_BUY_NEW_MEMBERSHIP',
        CLICK_ON_PLACE_ORDER_FROM_BUY_NEW_MEMBERSHIP: 'CLICK_ON_PLACE_ORDER_FROM_BUY_NEW_MEMBERSHIP',
        CLICK_ON_CONTINUE_SHOPPING_FROM_BUY_NEW_MEMBERSHIP: 'CLICK_ON_CONTINUE_SHOPPING_FROM_BUY_NEW_MEMBERSHIP',
        CLICK_ON_PAY_NOW_BUY_NEW_MEMBERSHIP: 'CLICK_ON_PAY_NOW_BUY_NEW_MEMBERSHIP',
        CLICK_ON_PURCHASE_AND_PAYLATER_BUY_NEW_MEMBERSHIP: 'CLICK_ON_PURCHASE_AND_PAYLATER_BUY_NEW_MEMBERSHIP',
        ORDER_SUCESSFUL: 'ORDER_SUCESSFUL',
        ORDER_FAIL: 'ORDER_FAIL',
        CLICK_ON_MY_ONLINE_ORDER: 'CLICK_ON_MY_ONLINE_ORDER',
        CLICK_ON_OPEN_ORDERS_FROM_MY_ONLINE_ORDER: 'CLICK_ON_OPEN_ORDERS_FROM_MY_ONLINE_ORDER',
        CLICK_ON_MY_DIARY: 'CLICK_ON_MY_DIARY',
        CLICK_ON_ADD_ACTIVITY_FROM_MY_DAIRY: 'CLICK_ON_ADD_ACTIVITY_FROM_MY_DAIRY',
        ACTIVITY_CREATED_SUCESSFULLY: 'ACTIVITY_CREATED_SUCESSFULLY',
        CLICK_ON_MY_INVOICES: 'CLICK_ON_MY_INVOICES',
        CLICK_ON_INVOICE_TO_VIEW_INVOICE_DETAIL: 'CLICK_ON_INVOICE_TO_VIEW_INVOICE_DETAIL',
        CLICK_ON_TRANSACTIONS_HISTORY_FROM_INVOICE_DETAIL: 'CLICK_ON_TRANSACTIONS_HISTORY_FROM_INVOICE_DETAIL',
        CLICK_ON_MY_CASES: 'CLICK_ON_MY_CASES',
        CLICK_ON_ARCHIVED_CASES: 'CLICK_ON_ARCHIVED_CASES',
        CLICK_ON_CREATE_CASE: 'CLICK_ON_CREATE_CASE',
        CLICK_ON_OPEN_CASES: 'CLICK_ON_OPEN_CASES',
        CLICK_ON_IN_PROGRESS_CASES: 'CLICK_ON_IN_PROGRESS_CASES',
        CLICK_ON_CLOSED_CASES: 'CLICK_ON_CLOSED_CASES',
        CLICK_ON_GENERAL_INQUIRY_FROM_CASES: 'CLICK_ON_GENERAL_INQUIRY_FROM_CASES'
    },
    alertMinDuration: 2000,
    alertDuration: 5000,
    idealAlertDuration: 5000,
    retryButtonAlertConfig: {
        buttonText: 'COMMON.Retry'
    },
    maxAlertDurationConfig: {
        duration: 5000
    },
    maxAlertDurationWithButtonConfig: {
        duration: 5000,
        buttonText: 'COMMON.Retry'
    },
    GeneralProfileID: 349

};

