import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SystemJsNgModuleLoader } from '@angular/core';

import { PostalCodeModel, SystemPostalCodeModel } from '@det/components/locations/Locations.model';
import { HttpService } from '@det/core/http.service';
import { Observable } from 'rxjs';
import { GlobalConstants } from '@det/core/global-constants';



@Injectable()
export class PostalCodeService {

    /**
     * constructor
     * @param http 
     */
    constructor(
        private http: HttpClient,
        private httpService: HttpService,
    ) {

    }

    tsvJSON(tsv: any) {

        const lines = tsv.split("\n");
        const result = [];
        const headers = lines[0].split(",");
        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentline = lines[i].split("\t");

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j].replace(/ /g, "_")] = currentline[j];
            }
            result.push(obj);

        }

        // return result; //JavaScript object

        return JSON.stringify(result); // JSON
    }

    SearchPostalCode(): Observable<Object> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8'

        });
        const options: any = {
            headers: headers
        };
        return this.http.get('../../assets/Config/PostalCode.txt', options);
    }

    SearchPostalCodeFromServer(postalCode: any) {
        const apiPath = `${GlobalConstants.ApiPath.System.GetSystemPostalCodeById}/${postalCode}`;
        return this.httpService.get<SystemPostalCodeModel>(apiPath);
    }
}

