export const ApiPath = {
  ClientConfig: {
    PutClientConfig: 'ims/ClientConfig',
    PostClientConfig: 'ims/ClientConfig',
    GetClientConfig: 'ims/ClientConfig', // {clientConfigurationID}
    DeleteClientConfig: 'ims/ClientConfig', // {clientConfigurationID}
    Toggle: 'ims/ClientConfig', // {clientConfigurationID} {status}
  },
  Auth: {
    PostAcquireToken: 'ims/Auth/AcquireToken',
    PostAuthorizationToken: 'ims/Auth/AuthorizationToken',
    VerifyLoggedInUser: 'ims/Auth/VerifyLoggedInUser',
    AuthIDServerAcquireIMSTokenPost: 'ims/Auth/IDServer/AcquireIMSToken',
    PostSignUp: 'ims/Auth/Signup',
    PutChangePassword: 'ims/Auth/ChangePassword',
    SetPassword: 'ims/Auth/SetUserNameAndPassword',
    ForgotPassword: 'ims/Auth/SendForgotPasswordEmail',
    ResetPassword: 'ims/Auth/ResetPassword',
    ChangePassword: 'ims/Auth/ChangePassword',
    GetUserInfo: 'ims/User/UserInfo',
    UpdateUserSetting: 'ims/UserSetting',
    GetUserSetting: 'ims/UserSetting', // {key id}
    CheckUserName: 'ims/User/CheckUserNameExistence',
    ChangeUsername: 'ims/User/UserName',
    SendForgotPasswordOrInvitationEmail:
      'ims/Auth/SendForgotPasswordOrInvitationEmail',
    SendInvitationToProUser: 'ims/User/SendInvitationToProUser',
  },
  ConsumerProfile: {
    GetConsumer: 'ims/Consumer',
    PutConsumer: 'ims/Consumer',
    DeleteConsumer: 'ims/GDPR/DeleteDataRequest',
    GetUserList: 'ims/Consumers',
    GetOptUserlist: 'ims/Consumers',
    UploadMediaFiles: 'ims/Consumer/Media',
    GetConsents: 'ims/Consumer/Consents',
    PutConsent: 'ims/Consumer/Consents',
    ExportData: 'ims/GDPR/ExportDataRequest',
    ForgetMe: 'ims/Consumer',
    GetAllConsents: 'ims/ConsentText',
  },
  IndividualMembership: {
    GetUserInfo: 'ims/IndividualMembership/MembershipInfo',
    BuyNewContract: 'ims/IndividualMembership/BuyContract',
    ActivatePaymentDetail: 'ims/IndividualMembership/Activate',
    UpdatePaymentDetail: 'ims/IndividualMembership/PaymentDetail',
    CreateAndLinkMainMember: 'ims/IndividualMembership/MainMember',
    LinkMainMember: 'ims/IndividualMembership/LinkMainMember', // id / mainmemberid
    SearchMainMember: 'ims/IndividualMembership/SearchMember',
    GetStats: 'ims/IndividualMembership/Stats',
    CancelContingent: 'ims/IndividualMembership/ApproveReject',
    CancelContract: 'ims/IndividualMembership/CancelContract',
    Update: 'ims/IndividualMembership',
    DeleteContract: 'ims/IndividualMembership/DeleteContract',
    DraftOrderMembership: 'ims/IndividualMembership/DraftOrder',
    DraftOrderContract: 'ims/IndividualMembership/DraftOrderContracts',
    CancelMembership: 'ims/IndividualMembership/Cancel', // {id}
    RenewMembersipContract: 'ims/IndividualMembership/DraftOrderRenew',
    MemberSignup: 'ims/IndividualMembership/Signup',
    AddTryout: 'ims/IndividualMembership/Tryout',
    GetAdminFee: 'ims/BusinessUnit/AdminFeeForClub',
    GetAdminFeeAnonymous: 'ims/Account/AdminFeeForClubAnonymous',
    UpdateIndividualMembership:
      'ims/IndividualMembership/UpdateMembershipFromSelfSignup', // {id}
    ReactiveMembership: 'ims/IndividualMembership/ReActivateMembership', // ID
    CheckEligibilityForReactivation:
      'ims/IndividualMembership/CheckEligibilityForReactivation', // ID
  },
  BusinessUnit: {
    GetList: 'ims/BusinessUnit',
    GetDetail: 'ims/BusinessUnit', // {businessUnitID}
    PutDetail: 'ims/BusinessUnit',
    PostDetail: 'ims/BusinessUnit',
    Toggle: 'ims/BusinessUnit', // {businessUnitID}/{status}
    UploadMediaFiles: 'ims/BusinessUnit/Media',
    GetStats: 'ims/BusinessUnit/Stats',
    GetTree: 'ims/BusinessUnit/Tree',
  },
  Order: {
    GetStats: 'ims/Order/stats',
    PostOrder: 'ims/Order',
    PutOrder: 'ims/Order', // {id}
    PostPayment: 'payment', // NOTE: Order/{id}/payment
    GetList: 'ims/Order',
    GetDetail: 'ims/Order', // {id}
    ToggleOrder: 'ims/Order', // {id}/{status},
  },
  Cart: {
    GetCart: 'ims/Cart',
    PostCart: 'ims/Cart',
    PutCart: 'ims/Cart', // {id}
    DeleteCart: 'ims/Cart', // {id}
  },
  Product: {
    GetList: 'ims/Product',
    GetListAnonymous: 'ims/Product/Anonymous',
    GetDetail: 'ims/Product',
    GetStats: 'ims/Product/stats',
    UploadMediaFiles: 'ims/Product/Media',
    Favourite: 'Favourite',
    GetWishList: 'ims/Product/MyFavourites',
  },
  UserGroups: {
    GetFunctionList: 'ims/Function',
    GetUserGroupList: 'ims/UserGroup/B2CUserGroups',
  },
  ProductCategory: {
    GetList: 'ims/ProductCategory',
    UploadMediaFiles: 'ims/Product/Media',
  },
  MembershipCategory: {
    Toggle: 'ims/MembershipCategory',
    GetDeatil: 'ims/MembershipCategory',
    Update: 'ims/MembershipCategory',
    GetList: 'ims/MembershipCategory',
    Post: 'ims/MembershipCategory',
    GetListAnonymous: 'ims/MembershipCategory/MembershipCategoriesAnonymous',
  },
  System: {
    GetCountries: 'ims/System/Countries',
    GetCurrencies: 'ims/System/Currencies',
    GetTimeZones: 'ims/System/TimeZones',
    GetAllCurrencies: 'ims/System/Currencies',
    GetSystemPostalCodeById: 'ims/System/PostalCode', // { id }
  },
  Recruitments: {
    GetList: 'ims/Recruitment',
    GetRecruitmentPointList: 'ims/Recruitment/RecruitmentHistory',
    PostRecruitment: 'ims/Recruitment',
  },
  Membership: {
    GetCategoryList: 'ims/ProductCategory',
    GetContract: 'ims/Product/b2c',
    GetContingent: 'ims/Product/b2c',
    PostMember: 'ims/IndividualMembership',
    GetList: 'ims/IndividualMembership',
    ActivateMembership: 'ims/IndividualMembership/Activate',
    ToggleMembership: 'ims/IndividualMembership',
    GetDetail: 'ims/IndividualMembership',
    GetMembershipInfoDetail: 'ims/IndividualMembership/MembershipInfo',
    CancelContract: 'ims/IndividualMembership/CancelContract', // {individualMembershipDetailID}
    CancelIndividualMembership: 'ims/IndividualMembership/Cancel', // {id}
    SendMembershipForApproval:
      'ims/IndividualMembership/SendMembershipForApproval',
    SendMembershipForApprovalAnonymous:
      'ims/IndividualMembership/SendMembershipForApprovalAnonymous', //{id}
    ActivateMembershipAnonymous:
      'ims/IndividualMembership/ActivateMembershipAnonymous',
  },

  Member: {
    GetList: 'engagement/Member',
    GetStats: 'engagement/Member/MembershipStatus/Stats',
    GetTeamStats: 'engagement/Member/TeamMembershipStatus/Stats',
    GetGroupArea: 'engagement/GroupArea',
    GetFunctionCategory: 'engagement/FunctionCategory',
    GetFunctionTypes: 'engagement/FunctionCategory/FunctionTypes',
    GetCountyCategory: 'engagement/Region/County',
    GetCousil: 'engagement/Region/Council',
    GetDetail: 'engagement/Member',
    PostMember: 'engagement/Member',
    GetFilterCount: 'engagement/Member/FilterCounts',
    PutMember: 'engagement/Member', // {id}
    GetIndividualMembershipDetail: 'engagement/IndividualMembership/b2c',
    Export: 'engagement/Member/Export',
    GetAvtalGiro: 'engagement/Member/AvtaleGiroUrl',
    GetAvtalGiroAnonymous: 'engagement/Member/AvtaleGiroUrlAnonymous', // {id}
    UpdateAvtalGiro: 'engagement/Member/AvtaleGiroUrl',
    UpdateAvtalGiroAnonymous: 'engagement/Member/AvtaleGiroUrlAnonymous', // {id}
    SelfSignUp: 'engagement/Member/SelfSignup',
    UpdateAvtalGiroStatus: 'engagement/Member/UpdateAvtaleGiroStatus',
    ExportEngagement: 'engagement/Member/Export'
  },
  User: {
    UploadMediaFiles: 'ims/User/Media',
    LogActivity: 'ims/User/AppActivity',
    Theme: 'ims/User/Theme',
  },
  Diary: {
    GetDiary: 'ims/Diary',
    UploadMediaFiles: 'ims/Diary/Media',
  },
  Grade: {
    Events: 'ims/Grade/Events',
  },
  Volunteer: {
    VolunteerPost: 'ims/Volunteer/Signup',
    Media: 'ims/Volunteer/Media',
  },
  Sponsor: {
    AddSponsor: 'ims/Sponsor/Anonymous',
    UploadSponsorMedia: 'ims/Sponsor/Media',
  },
  Payment: {
    CompletePayment: 'ims/Order', // Order/{id}/transaction/{transactionid}'
  },
  Case: {
    GetCase: 'ims/Case',
    GetArchivedCase: 'ims/Case/archived',
    GetStats: 'ims/Case/Stats',
    GetDetail: 'ims/Case', // {id}
    PostCase: 'ims/Case',
    ToggleCase: 'ims/Case', // {businessUnitID}/{status}
    UploadMediaFiles: 'ims/Case/Media',
    UpdateCaseReceiver: 'receiver',
    UpdateCaseOwner: 'owner',
    PostComment: '/ims/Case/comment',
    SearchMemberForCase: 'ims/Member/SearchMemberForCase',
  },
  AccountStyle: {
    GetAccountStyle: 'ims/AccountStyle',
    GetAccountStyleAnonymous: 'ims/AccountStyle/AccountStylesAnonymous',
  },
  Acitivity: {
    GetList: 'ims/Activity',
    PostActivity: 'ims/Activity',
    PutActivity: 'ims/Activity', // {id}
    GetDetail: 'ims/Activity', // {id
    PostActivityParticipant: 'participant', // {ims/Activity/{id}/participant}
    PutActivityStatus: 'ims/Activity', // {id} //{status},
    DeleteActivityParticipant: 'participant', // {ims/Activity/{id}/participant}
    GetActivityHistory: 'ims/Activity/history',
    RemoveMe: 'participant/me',
    DashboardActivityList: 'ims/Dashboard/MyDiary',
  },
  Event: {
    GetCalendarList: 'ims/Event/calendar',
    GetDetail: 'ims/Event', // {id}
    ToggleRSVPEvent: 'ims/Event', // {id}/RSVP/{participantID}/{rsvp}
    RSVP: 'RSVP',
    ParticipantsInterest: 'ims/Event', // {id}/Interest/{consumerProfileID}
    Interest: 'Interest',
    DashboardEventList: 'ims/Dashboard/MyEvents',
  },
  Account: {
    GetAccountDetail: 'ims/Account/AccountDetailsForB2C',
    GetAccountAnonymousDetail: 'ims/Account/Anonymous', // {id}
  },
  Department: {
    GetDepartmentStats: 'ims/Department/stats',
    GetDepartments: 'ims/Department',
    GetDepartmentsAnonymous: 'ims/Department/Anonymous',
    CreateDepartment: 'ims/Department',
    UpdateDepartment: 'ims/Department',
    DeleteDepartment: 'ims/Department',
    GetDepartment: 'ims/Department', // {id} for department id
    AccountLogo: 'ims/Account/AccountLogo',
  },
  BuyMembership: {
    GetFederationList: 'ims/BusinessUnit',
    GetAllConsents: 'ims/ConsentText',
    GetMemberDetails: 'ims/Member/MemberDetail',
    GetMemberCategory: 'ims/MembershipCategory',
    GetMembershipFees: 'ims/Product',
    GetClubs: 'ims/Account',
    GetTrainingFees: 'ims/Product',
    GetTrainingStyles: 'ims/AccountStyle',
    GetEshopProducts: 'ims/Product',
    GetCartDetails: 'ims/Cart',
    AdminFeeForOrganisation: 'ims/BusinessUnit/AdminFeeForOrganisation',
    AdminFeesForTraining: 'ims/BusinessUnit/AdminFeeForTraining',
    GetAccountDetailsForClub: 'ims/Account/AccountDetailsForB2C',
    SendMembershipForApproval:
      'ims/IndividualMembership/SendMembershipForApproval',
    ActivateMembershipByCart:
      'ims/IndividualMembership/ActivateMembershipByCart',
    ActivateTrainingByCart: 'ims/IndividualMembership/ActivateTrainingByCart',
    PostOrder: 'ims/Order',
    PutOrder: 'ims/Order', // {id}
    PostPayment: 'payment', // NOTE: Order/{id}/payment
    RefreshToken: 'ims/Auth/RefreshAcquireToken',
    GetIndividualMembershipDetail: 'ims/IndividualMembership/b2c',
    GetAccountDetails: 'ims/account/detail',
    GetDepartments: 'ims/Department',
  },
  Teams: {
    GetStats: 'ims/Team/statistics/B2C',
    GetEligibleTeam: 'ims/Team/eligibleteamformember',
    GetTeam: 'ims/Team',
    BaseTeam: 'ims/Team',
    AddMembersToTeam: 'members',
    getTeamDetail: 'ims/Team',
    GetTeamMember: 'ims/Team/teammembers',
    OptOutMember: 'member',
    Matchedpricematrix: 'ims/matchedpricematrix',
    PostCart: 'ims/Cart',
    PutCart: 'ims/Cart',
    getTeamAdminFee: 'ims/BusinessUnit/TeamAdminFeeForFederation',
  },
  Invoices: {
    GetList: 'ims/Invoice',
    GetInvoiceDetail: 'ims/Invoice', // {id
    GetInvoiceHistory: 'ims/GLAccounting/InvoiceHistory',
    SendInvoice: 'ims/Invoice/SendInvoice', // {id} The invoice identifier.
    ExportInvoiceList: 'ims/FinancialReport/Invoices/Export',
    ExportInvoiceDetail: 'ims/FinancialReport/InvoiceDetail/Export',
    PayViaBuypass: 'ims/Buypass/Pay/Initialize',
    updateBuypassStatus: 'ims/Buypass/Pay/Status',
    GetCreditNoteHistory: 'ims/CreditNote', // {id} The invoice identifier.
    SendCreditNote: 'ims/CreditNote/SendCreditNote', // {id} The invoice identifier.
    ExportCreditNote: 'ims/CreditNote/Export',
  },
  Communication: {
    SendBulkSMS: 'ims/Communication/SendBulkSMS',
    SendBulkEmail: 'ims/Communication/SendBulkEmail',
    SendBulkEmailSMSInvitation: 'ims/Communication/SendBulkOnboardingInvitation',
    SendBulkClubSMS: 'ims/Communication/SendBulkSMSToClub',
    SendBulkClubEmail: 'ims/Communication/SendBulkEmailToClub',
    SendInvitationEmail: 'ims/Communication/SendBulkInvitationEmail',
    getRecipientCounts: 'ims/Communication/GetRecipientCount',
    getRecipientEngagementCounts: 'engagement/Member/GetRecipientCount',
    SendMemberCommunication: 'ims/Communication/MemberCommunication',
    SendMemberEngagementCommunication: 'engagement/Member/MemberCommunication',
  },
};
