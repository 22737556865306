export const locale = {
    lang: 'nb-NO',
    data: {
        'COMMON': {
            'PortalName': 'NIF',
            'Filter': 'Filter',
            'ClearAll': 'Fjern alle',
            'ADDNEW': 'Opprett ny',
            'ClearFilter': 'Nullstill',
            'Edit': 'Rediger',
            'View': 'Visning',
            'Ok': 'Ok',
            'UnableToFetch': 'Kan ikke hente data fra server. Vennligst prøv igjen.',
            'ComingSoon': 'Kommer snart',
            'Showing': 'Showing',
            'of': 'of',
            'Cancel': 'Avbryt',
            'SomethingWentWrong': 'Noe gikk galt. Vennligst prøv igjen.',
            'Language': 'Språk',
            'Norwegian': 'Norsk',
            'English': 'English',
            'Theme': 'Tema',
            'Dark': 'Mørk',
            'Light':'Lys',
            'Federations': 'Forbund',
            'Logout': 'Logg ut',
        },
        'MEMBERSHIP': {
            'PageTitle': 'Medlemskap',
            'Gender': 'Kjønn',
            'Unknown': 'Ukjent',
            'Male': 'Mann',
            'Female': 'Kvinne',
            'Name': 'Navn',
            'Active': 'Aktiv',
            'All': 'Alle',
            'Pending': 'Venter på bekreftelse',
            'NotAutoRenew': 'Ikke automatisk fornyet',
            'Cancelled': 'Utmeldte',
            'AgeingOut': 'Nytt alderstrinn',
            'Membership': 'Medlemskap',
            'GroupArea': 'Særkrets',
            'Function': 'Funksjon',
            'County': 'Region',
            'PendingApproval': 'Pending Approval',
            'CancellationRequested': 'Forespørsel om kansellering',
            'NewInLastWeek': 'Nye påmeldte medlemmer',
            'ShownInterest': 'Shown Interest',
            'AgingOut': 'Aging out',
            'Automaticallyrenewed': 'Automatisk fornyet',
            'NotAutomaticallyRenewed': 'Ikke automatisk fornyet',
            'Members': 'Medlemmer',
            'ClearFilter': 'Clear filter',
            'DigitallyActive': 'Digitalt aktiv',
            'ActiveRole': 'Aktiv rolle',
            'ActivityParticipated': 'Deltok i aktivitet',
            'Memberships': 'Medlemskap',
            'GenderAndAge': 'Kjønn & Alder',
            'Total': 'Totalt',
            'MembershipInvoiceStatus': 'Medlemskapsfakturastatus',
            'TrainingFeeInvoiceStatus': 'Treningsavgiftfakturastatus',
            'TeamInvoiceStatus': 'Partideltakelse fakturastatus',
            'Paid': 'Betalt',
            'Overdue': 'Forfalt',
            'NotInvoiced': 'Ikke fakturert',
            'Invoiced': 'Fakturert',
            'Unpaid': 'Unpaid',
            'MembershipInvoice': 'Membership',
            'TrainingFeeInvoice': 'Training fee',
            'TeamInvoice': 'Team participation',
            'TeamMembershipStatus': 'Partideltakelse status',
            'Waiting': 'Venter',
            'InTeam': 'I parti',
            'RemovedFromTeam': 'Fjernet/meldtut av parti',
            'LeftTeam': 'Forlatt parti',
            'SportAndStyle': 'Stilart',
            'ClubLocation': 'Klubb',
            'MemberLocation': 'Member Location',
            'NotActive': 'Not Active',
            'SentForApproval': 'Venter på godkjenning',
            'SearchByNameOrrPersonID': 'Søk navn eller person ID',
            'InviteToIMS': 'Kommunikasjon',
            'SendInvitationLink': 'Send invitation link',
            'SendEmail': 'Send epost',
            'SendSMS': 'Send SMS',
            'Export': 'Eksport',
            'ExcelFile': 'Excel',
            'CSVFile': 'CSV',
            'PDFFile': 'PDF',
            'PersonID': 'Person ID',
            'SportsNumber': 'Idrettsnummer',
            'Age': 'Alder',
            'Phone': 'Telefon',
            'Email': 'Epost',
            'Location': 'Lokasjon',
            'ClubName': 'Klubb navn',
            'MembershipInvoiceHeader': 'Medlemskaps faktura',
            'MembershipNextInvoiceDate': 'Membership next invoice date',
            'TrainingFeeNextInvoiceDate': 'Training fee next invoice date',
            'Sport': 'Sport',
            'TrainingFeeInvoiceHeader': 'Treningsavgift faktura',
            'TeamParticipation': 'Partideltakelse',
            'TeamParticipationStatus': 'Partideltakelse status',
            'TeamParticipationInvoice': 'Partideltakelse faktura',
            'InvitationLinkHasBeenSend': 'Invitasjonslink er sendt',
            'Expired': 'Utløpt',
            'GroupFunctionTypeName': 'Gruppe funksjon',
            'GroupMembershipStatus': 'Group Membership Status',
            'GroupName': 'Gruppe',
            'GroupCounty': 'Fylke',
            'GroupAreaName': 'Særkrets',
            'GroupCouncil': 'Kommune',
            "East": "Øst",
            "West": "Vest",
            "South": "Sør",
            "Middle": "Midt",
            "North": "Nord",
           
            
        },
        'COMMUNICATION': {
            'TotalReceiver': 'Mottaker',
            'Message': 'Melding',
            'SendSMS': 'Send SMS',
            'SendEmail': 'Send E-post',
            'BulkSms': 'Felles SMS',
            'BulkEmail': 'Felles e-post',
            'Subject': 'Emne',
            'MessageIsRequired': 'Melding er påkrevd',
            'SubjectIsRequired': 'Emne er påkrevd',
            'BulkSmsSentSuccessfully': 'Felles SMS er sendt',
            'BulkEmailSentSuccessfully': 'Felles e-post er sendt',
            'InvitationSentSuccessfully': 'Invitasjon sendt',
            'EmailBodyRequired': 'e-post tekst er påkrevd',
            'EmailBody': 'e-post tekst',
            'NumberIsRequired': 'Phone number is required',
            'SendInvitation': 'Inviter',
            'InvalidPhoneNumbers': 'Vennligst oppgi et gyldig telefonnummer',
            'InvalidEmails': '	Vennligst oppgi en gyldig Epost adresse',
            'ExampleForEmail': ' eg: abc@abc.com,xyz@abc.com',
            'ExampleForPhone': ' eg: 123456789,568784125',
            'InviteMultipleMember': 'Inviter flere',
            'TextForSingleInvite': 'Ved å legge inn e-post og/eller mobilnummer til potensielle medlemmer vil systemet sende ut en lenke for selvregistrering. Mottakeren kan da selv registrere seg som et medlem av idrettslaget. Registreringen som blir gjennomført vil speiles i IMS.',
            'NoteForEitherPhoneOrEmailIsMandotary': 'Invitasjonen kan sendes til flere samtidig ved å trykke på "Inviter flere".',
            'CountryCode': 'Ext.',
            'or': 'Eller',
            'TooltipInfo': 'En eller flere mottakere kan ha samme kontakt information. Meldingen sendes kun en gang til samme kontakt info.',
            'SuccessMessageSMS': 'Meldingen ble sendt til {{Count}}',
            'SuccessMessageEmail': 'Eposten ble sendt til {{Count}}'
        },
    }
};



