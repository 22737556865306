import { CommonModule } from '@angular/common';
import { ContentModule } from './content/content.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MainComponent } from './main.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgModule } from '@angular/core';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    ContentModule,
    ToolbarModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    MatSidenavModule,
  ],
  exports: [MainComponent]
})
export class MainModule { }
