export const locale = {
    lang: 'en-GB',
    data: {
        'PageTitle': 'Login | NIF',
        'ForgetPasswordPageTitle': 'Forget Password | NIF',
        'SingUpPageTitle': 'Sign up | NIF',
        'WELCOME': 'Welcome to NIF!',
        'loginwithIDserver': 'Login with idrettens ID',
        'LOGINTOYOURACCOUNT': 'LOGIN TO YOUR ACCOUNT',
        'RememberMe': 'Remember Me',
        'ForgotPassword': 'Forgot Password?',
        'Login': 'Login',
        'Emailisrequired': 'Email is required',
        'ValidEmail': 'Please enter a valid email address',
        'PasswordRequired': 'Password is required',
        'login': 'Login',
        'Password': 'Password',
        'Email': 'Email',
        'InvalidUserName': 'Invalid Username',
        'UserNameisrequired': 'Username is required',
        'UserNameAlreadyExists': 'Username is already exists. Please choose unique one.',
        'UserName': 'Username',
        'RecoverPassword': 'RECOVER YOUR PASSWORD',
        'SendResetLink': 'SEND RESET LINK',
        'GoBackLogin': 'Go Back to Login',
        'UsersNotAuthorized': 'User not authorized',
        'OR': 'OR',
        'DontHaveAccount': 'Don\'t have an account?',
        'CreateAccount': 'Create an account',
        'ForgetPassword': 'Forgot Password?',
        'CreateAnAccount': 'CREATE AN ACCOUNT',
        'ConfirmPassword': 'Password (Confirm)',
        'ConsentOriginID': 'Consent Origin ID',
        'ConsentOriginIDRequired': 'Consent Origin ID Required',
        'ConfirmPasswordMatch': 'Confirm Password not matched',
        '_2002': 'User Already Exists',
        'SomethingWentWrong': 'Something Went Wrong',
        'AccountCreated': 'Account Created Successfully',
        'SessionExpired': 'Your session has been expired',
        'PushTokenRequired': 'Push Notification Denied',
        'PushTokenRequiredText': 'Push Notification has been denied by user or Browser not supported. It would be great if you provide access to notify even if you are not on this portal',
        'SetPasswordTitle': 'Set Password',
        'ResetPasswordTitle': 'Reset Password',
        'PasswordSet': 'Your Password has been set. Please login with your new password.',
        'Passwordreset': 'Your Password has been reset. Please login with your new password.',
        'PasswordForgot': 'You will receive a password recovery mail on your email id.',
        'UserNotFound': 'User not found. Please check your username which you entered.',
        'UnableToGetUserInfo': 'System is not able to get your information. Please try after some time.',
        'TokenExpiredOrInvalidEmail': 'The link has been expired.',
        'TokenExpired': 'The link has been expired.',
        'InvalidEmailID': 'The entered email id is not correct.',
        'ConsumerProfileNotFound': 'Consumer Profile Not Found',
        'InValidLogin': 'Unauthorised user login attempted! Try logging in again with the correct user credentials.',
        'POSTLOGIN': {
            'PageTitle': 'Please choose Profile | NIF',
            'Welcome': 'Welcome',
            'Intro': 'We have found {{totalProfiles}} profiles associated with your credentials.' +
                'Please choose one from them. You can also switch after logged in by click on my profile on top right.',
            'NotYou': 'Not You?',
            'Signout': 'Sign Out',
            'PleaseWait': 'Please Wait... | NIF',
            'Unknown': 'Unknown',
            'Renewed': 'Renewed',
            'Expired': 'Expired',
            'Cancelled': 'Cancelled',
            'Active': 'Active',
            'InActive': 'Inactive',
            'Pending': 'Pending',
            'MainMember': 'Linked Membership',
            'SentForApproval': 'Send For Approval',
            'Approved': 'Approved',
            'LinkedMemberShipWith': 'Linked MemberShip with',
            'Consent': 'Please give consent',
            'SaveConsent': 'Save consent',
            'updateConsentWayText': 'The settings can be changed in your profile.'
        },
        'MEMBERSHIP': {
            'PageTitlePublic': 'Membership Signup',
            'ThankYouMessage': 'Thank you for registration'
        },
        "VOLUNTEER": {
            'PageTitlePublic': 'Volunteer Signup',
            'PageTitle': 'Volunteer'
        },
        "SPONSORSHIP": {
            'PageTitle': 'Sponsor Registration'
        }
    }
};



