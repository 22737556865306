export const Routes = {
    auth: 'auth',
    b2c: 'b2c',
    app: 'app',
    apps: 'apps',
    other: 'other',
    public: 'public',
    consumer: 'consumer',
    Signin: 'signin',
    SignOut: 'signout',
    Auth: 'auth',
    SignUp: 'signup',
    Membership: 'membership',
    Member: 'member',
    PostLogin: 'post-login',
    ResetPassword: 'reset-password',
    SetPassword: 'set-password',
    ForgetPassword: 'forget-password',
    Dashboard: 'b2c/dashboard',
    MyMembership: 'my-membership',
    ChangePassword: 'change-password',
    ChangeUserName: 'change-username',
    _404: '404',
    _500: '500',
    _401: '401',
    Maintenance: 'maintenance',
    ComingSoon: 'coming-soon',

    orders: 'orders',
    gdpr: 'consents',
    profile: 'profile',
    EShop: 'eshop',
    cart: 'cart',
    ShippingAddress: 'shipping-address',
    PaymentMethod: 'payment-method',
    TrainingDiary: 'training-diary',
    Volunteer: 'volunteer',
    VolunteerSignup: 'volunteersignup',
    cases: 'cases',
    Recruitment: 'recruitment',
    Sponsorship: 'sponsorship',
    PostPayment: 'post-payment',
    EventsRsvp: 'events/rsvp',
    Teams: 'teams',
    MyTeams: 'myteams',
    MyInvoices: 'my-invoices',
    BuyMembership: 'buy-membership',
    BuyMembershipEdit: 'buy-membership/edit-cart',
    InvalidLogin: 'invalid',
    loginwithims: 'loginwithims',
    onBoarding: 'onboarding'
};
