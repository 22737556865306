
import {of as observableOf,  BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from '../core/global-constants';
import { CustomCookieService } from '../core/cookie.service';
import { TranslationLoaderService } from '@det/services/translation-loader.service';
import { locale as norwegian } from '../../app/main/content/apps/i18n/no';
import { locale as english } from '../../app/main/content/apps/i18n/en';


// Define the default config
const DEFAULT_CONFIG = {
    layout: {
        navigation: 'left', // 'right', 'left', 'top', 'none'
        navigationFolded: false, // true, false
        toolbar: 'above', // 'above', 'below', 'none'
        footer: 'below', // 'above', 'below', 'none'
        mode: 'fullwidth' // 'boxed', 'fullwidth'
    },
    colorClasses: {
        toolbar: 'det-bg-dark',
        navbar: 'transparent',
        footer: 'transparent',
        windowToolbar: 'primary-button-bg',
        buttonColor: 'primary-button-bg'
    },
    customScrollbars: true,
    routerAnimation: 'none', // fadeIn, slideUp, slideDown, slideRight, slideLeft, none
    shortLogo: '/assets/images/logos/NIFLOGO.svg',
    largeLogo: '/assets/images/logos/NIFLOGO.svg'
};

// Create the injection token for the custom config
export const FUSE_CONFIG = new InjectionToken('fuseCustomConfig');

@Injectable()
export class ConfigService {
    config: any;
    defaultConfig: any;
    onConfigChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param router
     * @param platform
     * @param config
     */
    constructor(
        private router: Router,
        public platform: Platform,
        private http: HttpClient,
        private titleService: Title,
    private translationLoader: TranslationLoaderService,
    private translateService: TranslateService,
        private customCookieService: CustomCookieService,
        @Inject(FUSE_CONFIG) @Optional() config
    ) {

    this.translationLoader.loadTranslations(english, norwegian);
    // Set the default settings from the constant
        const cookieConfig = localStorage.getItem(GlobalConstants.CookieKeys.PreferenceConfig);
        if (cookieConfig !== undefined && cookieConfig !== null && cookieConfig !== '') {
            config = JSON.parse(cookieConfig);
        } else {
            localStorage.setItem(GlobalConstants.CookieKeys.PreferenceConfig, JSON.stringify(DEFAULT_CONFIG));
            this.defaultConfig = DEFAULT_CONFIG;
        }


        // If custom config provided with forRoot,
        // use them as default config...
        if (config) {
            this.defaultConfig = config;
        }

        /**
         * Disable Custom Scrollbars if Browser is Mobile
         */
        if (this.platform.ANDROID || this.platform.IOS) {
            this.defaultConfig.customScrollbars = false;
        }

        // Set the config from the default config
        this.config = { ...this.defaultConfig };

        // Reload the default settings for the
        // layout on every navigation start
        router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.setConfig({
                        layout: this.defaultConfig.layout
                    }
                    );
                }
            }
        );

        // Create the behavior subject
        this.onConfigChanged = new BehaviorSubject(this.config);
    }

    /**
     * Set the new config from given object
     *
     * @param config
     */
    setConfig(config): void {
        // Set the config from the given object
        // Ugly, but works for now...
        this.config = {
            ...this.config,
            ...config,
            layout: {
                ...this.config.layout,
                ...config.layout,
            },
            colorClasses: {
                ...this.config.colorClasses,
                ...config.colorClasses
            }
        };
        // Trigger the event
        this.onConfigChanged.next(this.config);
    }

    readConfigData(): Observable<any> {
        const localConfigData = this.customCookieService.getCookie(GlobalConstants.CookieKeys.LocalConfig);
        if (localConfigData === null) {
            const configData = this.http.get('../../assets/Config/Config.txt');
            configData.subscribe(data => {
                this.customCookieService.createCookie(GlobalConstants.CookieKeys.LocalConfig, data, 0);
            });
            return configData;
        } else {
            return observableOf(localConfigData);
        }
    }

    setTitle(title) {
        this.titleService.setTitle(this.translateService.instant(title) + ' - ' + this.translateService.instant('COMMON.PortalName'));
    }

    getCountryCode(): Observable<any> {
        return this.http.get('../../assets/Config/countryCode.json');
    }
    
    setTitleWithSubscription(title, onLangChanged = null) {
        this.setTitle(title);
        onLangChanged = this.translateService.onLangChange
        .subscribe((event) => {
            this.setTitle(title);
        }); 
        return onLangChanged;   
    }
}

