import { NgModule } from '@angular/core';

import { IfOnDomDirective } from './fuse-if-on-dom/fuse-if-on-dom.directive';
import { PerfectScrollbarDirective } from './fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { MatSidenavHelperDirective, MatSidenavTogglerDirective } from './fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { NumberDirective } from './fuse-numberonly/numbers-only.directive';
import { PasswordDirective } from './password.directive';
import { AddRemoveClassDirective } from './addRemoveClass.directive';
import { LoaderDirectorDirective } from './custom-loader/loader-director.directive';
import { LoaderDirectorForIconDirective } from './custom-loader/loader-director-icon.directive';
@NgModule({
    declarations: [
        IfOnDomDirective,
        MatSidenavHelperDirective,
        MatSidenavTogglerDirective,
        PerfectScrollbarDirective,
        NumberDirective,
        PasswordDirective,
        AddRemoveClassDirective,
        LoaderDirectorDirective,
        LoaderDirectorForIconDirective
    ],
    imports: [],
    exports: [
        IfOnDomDirective,
        MatSidenavHelperDirective,
        MatSidenavTogglerDirective,
        PerfectScrollbarDirective,
        NumberDirective,
        PasswordDirective,
        AddRemoveClassDirective,
        LoaderDirectorDirective,
        LoaderDirectorForIconDirective
    ]
})
export class DirectivesModule {
}
