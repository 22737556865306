import { AcquireTokenModel, AuthorizationResponseModel, AuthorizationTokenModel, LoginModel, SignUpModel,
         UserInfoModel, UserSettingModel, VerifyLoggedInUserModel, VerifyLoggedInUserResponseModel } from 'app/main/content/auth/sign-in/login-model';
import { CurrencyModel, ResponseModel } from './common.model';
import { MembershipDetailModel } from 'app/main/content/apps/member/member.model';
import { EventEmitter, Injectable } from '@angular/core';

import { CustomCookieService } from './cookie.service';
import { GlobalConstants } from './global-constants';
import { HttpService } from './http.service';
import { LoginUserModel } from './user-model';
import { ModulesModel } from "@det/core/user-model";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserType } from './common-enums';

@Injectable()
export class AuthService {
  private _loginInfo: LoginUserModel;

  constructor(private httpService: HttpService, private customCookieService: CustomCookieService, private router: Router,
  ) { }
  userProfileChanged$: EventEmitter<LoginUserModel>;

  get allowToAccess(): boolean {
    const loginInfoModel = this.getFullUserInfo();
    return (loginInfoModel != null);
  }

  get isLoggedIn(): boolean {
    const loginInfoModel = this.getUserInfo();
    return (loginInfoModel != null && this.isProUser);
  }

  get isProUser(): boolean {
    const ispro = this.customCookieService.getCookie(GlobalConstants.CookieKeys.IsProUser);
    return ((ispro === "true") ? true : false);
  }

  getProUsers() {
    const userInfo = this.getFullUserInfo();
    if (userInfo.ProUsers !== undefined) {
      return userInfo.ProUsers;
    } else {
      return {};
    }
  }

  SignUp(SignUpFormModel: SignUpModel): Observable<any> {
    return this.httpService.post<any>(GlobalConstants.ApiPath.Auth.PostSignUp, SignUpFormModel);
  }

  signIn(loginModel: LoginModel): Observable<any> {
    return this.httpService.post<AcquireTokenModel>(GlobalConstants.ApiPath.Auth.PostAcquireToken, loginModel);
  }
  VerifyLoggedInUser(verifyLogged: VerifyLoggedInUserModel): Observable<any> {
    // return this.httpService.post<boolean>(GlobalConstants.ApiPath.Auth.VerifyLoggedInUser, loginModel);
    return this.httpService.post<VerifyLoggedInUserResponseModel>(GlobalConstants.ApiPath.Auth.VerifyLoggedInUser, verifyLogged, { Type: 'TokenVerification' });
  }
  getAuthorization(authorizationData: AuthorizationTokenModel): Observable<any> {
    return this.httpService.post<AuthorizationResponseModel>(GlobalConstants.ApiPath.Auth.PostAuthorizationToken, authorizationData);
  }
  AuthIDServerAcquireIMSTokenPost(userData): Observable<any> {
    return this.httpService.post<AuthorizationResponseModel>(GlobalConstants.ApiPath.Auth.AuthIDServerAcquireIMSTokenPost,
      userData,
      { Type: 'TokenVerification' });
  }
  signOut() {
    this._loginInfo = undefined;
    this.customCookieService.deleteAllCookies();
    localStorage.removeItem(GlobalConstants.CookieKeys.AuthKey);
    localStorage.removeItem(GlobalConstants.CookieKeys.ConsumerToken);
    localStorage.removeItem(GlobalConstants.CookieKeys.GeoData);
    localStorage.removeItem(GlobalConstants.CookieKeys.HistoryData);
    localStorage.removeItem(GlobalConstants.CookieKeys.ModuleList);
    localStorage.removeItem(GlobalConstants.CookieKeys.PreferenceConfig);
    localStorage.removeItem(GlobalConstants.CookieKeys.CurrencyData);
    localStorage.removeItem(GlobalConstants.CookieKeys.CompleteUserInfo);
    localStorage.removeItem(GlobalConstants.CookieKeys.TotalMediaUploading);
    localStorage.removeItem(GlobalConstants.CookieKeys.IsProUser);
    localStorage.removeItem(GlobalConstants.CookieKeys.RememberMe);
    localStorage.clear();

  }
  handleLogout(response) {
    if (response === 401) {
      this.signOut();
    }
  }
  GetUserInfo(): Observable<UserInfoModel> {
    const apiPath = `${GlobalConstants.ApiPath.Auth.GetUserInfo}`;
    return this.httpService.get<UserInfoModel>(apiPath);
  }
  /**
   * Used to update user setting to know last what activity done by user
   * @param userSetting 
   */
  UpdateUserSetting(userSetting: UserSettingModel) {
    const apiPath = `${GlobalConstants.ApiPath.Auth.UpdateUserSetting}`;
    return this.httpService.put<ResponseModel>(apiPath, userSetting);
  }
  /**
   * Used to get user setting to know last what activity done by user
   * @param key 
   */
  GetUserSetting(key): Observable<UserSettingModel> {
    const apiPath = `${GlobalConstants.ApiPath.Auth.GetUserSetting}/${key}`;
    return this.httpService.get<UserSettingModel>(apiPath);
  }

  saveTokeInfo(token: string, expirationHours?: number) {
    // this.customCookieService.deleteAllCookies();
    this.customCookieService.createCookie(GlobalConstants.CookieKeys.Token, token, expirationHours);
  }


  saveCompleteUserInfo(userModel?: UserInfoModel) {
    if (userModel && userModel.ProUsers && userModel.ProUsers.length) {
      userModel.ProUsers.forEach((user) => {
        if (user.DepartmentName) {
          user.Name = user.DepartmentName;
        } else if (user.AccountName) {
          user.Name = user.AccountName;
        } else if (user.BusinessUnitName) {
          user.Name = user.BusinessUnitName;
        } else {
          user.Name = '';
        }
      });
    }
    localStorage.setItem(GlobalConstants.CookieKeys.CompleteUserInfo, JSON.stringify(userModel));
  }

  saveLoginInfo(ProUserID) {

    const userData = this.getFullUserInfo();
    const loginUserinfo: LoginUserModel = new LoginUserModel(userData);
    const currentProUserData = userData.ProUsers.filter(data => data.ProUserID === ProUserID);
    if (currentProUserData.length > 0) {
      loginUserinfo.UserTypeID = currentProUserData[0].UserTypeID;
      loginUserinfo.AccountID = currentProUserData[0].AccountID;
      loginUserinfo.BusinessUnitID = currentProUserData[0].BusinessUnitID;
      loginUserinfo.Modules = currentProUserData[0].Modules;
      loginUserinfo.AccountName = currentProUserData[0].AccountName;
      loginUserinfo.BusinessUnitName = currentProUserData[0].BusinessUnitName;
      loginUserinfo.DepartmentID = currentProUserData[0].DepartmentID;
      loginUserinfo.DepartmentName = currentProUserData[0].DepartmentName;
      loginUserinfo.NIFOrgID = currentProUserData[0].NIFOrgID;
      this.saveModuleInfo(loginUserinfo.Modules);
      localStorage.setItem(GlobalConstants.CookieKeys.AuthKey, JSON.stringify(loginUserinfo));
    } else {
      this.signOut();
    }
  }

  UpdateProfileLocal(ProfileData: MembershipDetailModel) {

    const userFullData = this.getFullUserInfo();
    const userData = this.getUserInfo();
    userFullData.FirstName = userData.FirstName = ProfileData.FirstName;
    userFullData.MiddleName = userData.MiddleName = ProfileData.MiddleName;
    userFullData.LastName = userData.LastName = ProfileData.LastName;
    userFullData.Gender = userData.Gender = ProfileData.Gender;
    userFullData.BirthDate = userData.BirthDate = ProfileData.BirthDate;
    userFullData.PhoneNumber = userData.PhoneNumber = ProfileData.PhoneNumber;
    userFullData.MobilePhone = userData.MobilePhone = ProfileData.MobilePhone;
    userFullData.Locations = userData.Locations = ProfileData.Locations;
    userFullData.Medias = userData.Medias = ProfileData.Medias;
    userFullData.NationalityID = userData.NationalityID = ProfileData.NationalityID;
    userFullData.MobilePhoneCountryCode = userData.MobilePhoneCountryCode = ProfileData.MobilePhoneCountryCode;
    userFullData.PhoneNumberCountryCode = userData.PhoneNumberCountryCode = ProfileData.PhoneNumberCountryCode;
    userFullData.ContactID = userData.ContactID = ProfileData.ContactID;
    this.saveCompleteUserInfo(userFullData);
    localStorage.setItem(GlobalConstants.CookieKeys.AuthKey, JSON.stringify(userData));
  }

  updateDepartmentRequired(flag: string) {
    localStorage.setItem(GlobalConstants.CookieKeys.IsDepartmentVisibleForClubUser, flag);
  }
  get isDepartmentVisibleForClubUser() {
    const flag = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.IsDepartmentVisibleForClubUser));
    return (flag !== null && flag !== undefined && flag === true) ? true : false;
  }

  saveModuleInfo(ModuleData: ModulesModel[]) {
    localStorage.setItem(GlobalConstants.CookieKeys.ModuleList, JSON.stringify(ModuleData));
  }

  getFullUserInfo() {
    let userData = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.CompleteUserInfo));
    if (localStorage.getItem(GlobalConstants.CookieKeys.CompleteUserInfo) !== null) {
      userData = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.CompleteUserInfo));
    }
    return <UserInfoModel>userData;
  }

  getUserInfo() {
    let userData = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.AuthKey));
    if (localStorage.getItem(GlobalConstants.CookieKeys.AuthKey) !== null) {
      userData = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.AuthKey));
    }
    return <LoginUserModel>userData;
  }

  GetLocalRights() {
    const LocalRights = localStorage.getItem(GlobalConstants.CookieKeys.ModuleList);
    return <ModulesModel[]>JSON.parse(LocalRights);
  }


  GetActionRightsByID(ModuleID: string, ActionID: string) {
    const UserRights = this.GetLocalRights();
    if (UserRights === null) {
      return false;
    }
    let hasPermission = -1;
    const hasModule = UserRights.filter(data => data.ModuleID.toUpperCase() === ModuleID.toUpperCase());
    if (hasModule.length > 0) {
      hasPermission = hasModule[0].ActionRights.findIndex(data => data.ActionRightID === ActionID);
    }
    return (hasPermission !== -1) ? true : false;
  }
  GetUIComponentRightsByID(ModuleID: string, UIComponentID: string) {
    const UserRights = this.GetLocalRights();
    if (UserRights === null) {
      return false;
    }
    let hasPermission = -1;
    const hasModule = UserRights.filter(data => data.ModuleID === ModuleID);
    if (hasModule.length > 0) {
      hasPermission = hasModule[0].UIComponents.findIndex(data => data.UIComponentID === UIComponentID);
    }
    return (hasPermission !== -1) ? true : false;
  }
  GetModuleActionUIRightsByID(ModuleID: string, ActionID: string, UIComponentID: string): boolean {

    if (this.isSystemUser) {
      return true;
    }
    if (ActionID !== null) {
      return this.GetActionRightsByID(ModuleID, ActionID);
    } else if (UIComponentID !== null) {
      return this.GetUIComponentRightsByID(ModuleID, UIComponentID);
    } else {
      return false;
    }
  }
  GetCountOfActionRightsByID(ModuleID: string) {
    const UserRights = this.GetLocalRights();
    if (UserRights === null) {
      return 0;
    }
    const hasModule = UserRights.filter(data => data.ModuleID === ModuleID);
    if (hasModule.length > 0) {
      return hasModule[0].ActionRights.length;
    } else {
      return 0;
    }
  }
  GetCountOfUIComponentRightsByID(ModuleID: string) {
    const UserRights = this.GetLocalRights();
    if (UserRights === null) {
      return 0;
    }
    const hasModule = UserRights.filter(data => data.ModuleID === ModuleID);
    if (hasModule.length > 0) {
      return hasModule[0].UIComponents.length;
    } else {
      return 0;
    }
  }

  get isSystemUser() {
    const userData = this.getUserInfo();
    return (userData !== undefined && userData.UserTypeID === UserType.SystemUser) ? true : false;
  }
  get isOrganisationUser() {
    const userData = this.getUserInfo();
    return (userData !== null && userData !== undefined && userData.UserTypeID === UserType.OrganisationUser) ? true : false;
  }
  get isBusinessUnitUser() {
    const userData = this.getUserInfo();
    return (userData !== null && userData !== undefined && userData.UserTypeID === UserType.BusinessUnitUser) ? true : false;
  }
  get isAccountUser() {
    const userData = this.getUserInfo();
    return (userData !== null && userData !== undefined && userData.UserTypeID === UserType.AccountUser) ? true : false;
  }
  get isB2CUser() {
    const userData = this.getUserInfo();
    return (userData !== null && userData !== undefined && userData.UserTypeID === UserType.B2CUser) ? true : false;
  }
  get isDepartmentUser() {
    const userData = this.getUserInfo();
    return (userData !== null && userData !== undefined && userData.UserTypeID === UserType.DepartmentUser) ? true : false;
  }

  saveCurrencyInfo(CurrencyData: CurrencyModel[]) {
    localStorage.setItem(GlobalConstants.CookieKeys.CurrencyData, JSON.stringify(CurrencyData));
  }

  GetCurrencyInfo() {
    const CurrencyInfoList = localStorage.getItem(GlobalConstants.CookieKeys.CurrencyData);
    if (CurrencyInfoList === null) {
      return null;
    }
    return <CurrencyModel[]>JSON.parse(CurrencyInfoList);
  }

  GetCurrentUserID() {
    const LastSelectedProUser = JSON.parse(localStorage.getItem(GlobalConstants.CookieKeys.LastSelectedProUser));
    if (LastSelectedProUser === null || LastSelectedProUser === undefined) {
      return null;
    }

    return LastSelectedProUser['SelectedProUserID'];
  }

  currentUserType() {
    const userData = this.getUserInfo();
    return userData && userData.UserTypeID ? userData.UserTypeID : null;
  }
}
