export const locale = {
    lang: 'en-GB',
    data: {
        'SystemUser': 'System Admin',
        'OrganizationUser': 'Organization  Admin',
        'BusinessUser': 'Federation Admin',
        'AccountUser': 'Club Admin',
        'DepartmentUser': 'Department User'
    }
};



