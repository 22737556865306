export enum GenderEnum {
    Male = 1,
    Female = 2,
    Unknown = 0
}

export enum StatusEnum {
    Unknown = 0,
    Draft = 1, // Draft 1
    Active = 2,
    InActive = 4, // inactive 4
    Deleted = 8,
    Archived = 16,
}

export enum LocationType {
    Unknown = 0,
    Home = 1,
    Work = 2,
    Office = 3,
    Headquarter = 4,
    RemoteOffice = 5,
}

export enum MediaType {
    Unknown = 0,
    Image = 1,
    // GIF = 2,
    // Video = 3,
    // Audio = 4,
    Doc = 5,
}

export enum UserType {
    B2CUser = "3708f320-907a-413e-be1c-5f07017f391f",
    AccountUser = "b4a102f8-805e-4e14-aee8-dec9c4c2f0b9",
    BusinessUnitUser = "fecb6264-4ebb-4ea7-8632-2cb7d26171be",
    OrganisationUser = "dffdb89b-ecd2-40fa-b833-a3c06ddcd110",
    SystemUser = "2c82ca99-1ea5-4f4e-a9a4-dd3606fa7726",
    ApplicationUser = "1c80e236-0f29-4794-940a-25cd2b4dcbb5",
    IntegrationUser = "a236de17-b145-4cd1-9f90-16839b7f9fc7",
    DepartmentUser = "605beee0-be85-4fbb-abea-af9eacf637ae"

}

export enum UserGroupType {
    Board = 1,
    Committee = 2,
    Group = 3,
    SubGroup = 4,
    Team = 4,
    Department = 5,
}

export enum UserGroupMembershipType {
    Member = 1,
    President = 2,
    Admin = 3,
}

export enum UserGroupMembershipHistoryEventType {
    Added = 1,
    Updated = 2,
    Removed = 3,
}

export enum ProductType {
    Unknown = 0,
    Physical = 1,
    Digital = 2,
    Access = 3,
    Time = 4,
    Ticket = 5,
    TryOuts = 6,
    Team = 7,
}
export enum ProductStatus {
    Unknown = 0,
    Featured = 1,
    FastTrack = 2,
    FastTable = 4,
    ClickAndCollect = 8
}

export enum ProductSortBy {
    PriceAscending = 1,
    PriceDescending = 2,
    LastModifiedDateDescending = 3
}


export enum MembershipStatusEnum {
    Unknown = 0,
    Pending = 1,
    Active = 2,
    SentForApproval = 3,
    Expired = 4,
    Cancelled = 5,
    Approved = 6,
    CancelRequest = 100,
}
export enum TryoutStatusEnum {
    Unknown = 0,
    Pending = 1,
    Active = 2,
    Expired = 3,
    Rejected = 4,
}

export enum DurationType {
    Days = 2,
    Weeks = 3,
    Months = 4,
    Years = 5,
    Quarterly = 6,
}
export enum ActivityAreaTypeEnum {
    Unknown = 0,
    East = 1,
    South = 2,
    West = 3,
    Middle = 4,
    North = 5,
}

export enum AfterPaymentPath {
    Membership = "1",
    Order = "2",
}

export enum DeliveryTypeEnum {
    Deliver = "1",
    Pickup = "2",
}

export enum MembershipBuyRenew {
    MembershipBuy = "1",
    MembershipRenew = "2",
    ContractBuy = "3",
    ContractRenew = "4",

}
export enum InvoicePaymentModeID {
    Unknown = "0",
    NKFPaymentSolution = "1",
    BankAxeptNAV = "2",
    BankAxept = "3",
}

export enum PaymentTypeEnum {
    Unknown = 0,
    PrePaid = 1,
    PostPaid = 2,
}
export enum AddEditActionEnum {
    Add = 0,
    Edit = 1,
}
export enum TeamsTypeEnum {
    Board = 1,
    Sports = 2,
    Committee = 3,
    SelectionList = 4,
}
export enum TeamType {
    Team = 2,
    Committee = 3,
}

export enum DocumentTypeEnum {
    PDF = 1,
    XLS = 2,
    XLSX = 3,
    DOCX = 4,
    CSV = 5,
    PPTX = 6,
    IMAGE = 7,
    XPS = 8,
}

export enum AlertEnum {
    Success = 1,
    Error = 2,
    Info = 3,
    Warning = 4
}

export enum OrderStatusEnum {
    Unknown = 0,
    Ordered = 1,
    Paid = 2,
    Approved = 3,
    Fulfilled = 4,
    Cancelled = 5,
}

export enum ThemeEnum {
    LightTheme = 1,
    DarkTheme = 2,
}

export enum Icons {
    Excel = 'excelIcon',
    Grid = 'GridIcon',
    PDF = 'PDFico',
    CSV = 'CSVico',
    SuccessIcon = 'SuccessIcon',
    ErrorIcon = 'ErrorIcon',
    InfoIcon = 'InfoIcon',
    WarningIcon = 'WarningIcon',
    avaTeamIco = 'avaTeamIco',
    waitingIcon = 'waitingIcon',
    InTeamIco = 'InTeamIco',
    CreditNoteIconDark = 'CreditNoteIconDark',
    CreditNoteIconLight = 'CreditNoteIconLight',
}

export enum FilterTypeEnum {
    DateDurationPicker,
    DateTimePicker,
    DatePicker,
    YearPicker,
    CheckBoxMultiple,
    CheckBoxSingle,
    ButtonToggle,
    StringType,
    TreeType,
    DeptFilter
}

export enum TeamGenderEnum {
    Unknown = 0,
    Male = 1,
    Female = 2,
    All = 3,
}


export enum LevelTypeEnum {
    Unknown = 0,
    Organization = 1,
    Federation = 2,
    Club = 3,
    UserGroup = 4,
    Team = 5
}

export enum MemberClassificationEnum {
    Unknown = 0,
    Active = 1,
    Paying = 2,
    ActivePaying = 3,
    ActiveNonPaying = 4,
    PayingActive = 5,
    PayingPassive = 6,
    PotentialMembers = 7
}

export enum InvoiceStatusEnum {
    Unknown = 0,
    NotInvoiced = 1,
    Invoiced = 2,
    Paid = 3,
    Overdue = 4
}

export enum TeamMembershipStatusEnum {
    Waiting = 1,
    InTeam = 2,
    RemovedFromTeam = 3,
    LeftTeam = 4
}

export enum MembershipTypeEnum {
    NewInLastWeek = 1,
    // ShownInterest = 2,
    // PendingApproval = 3,
    CancellationRequested = 100,
    AgingOut = 4,
    Automaticallyrenewed = 5,
    NotAutomaticallyRenewed = 6
}

export enum MemberRole {
    DigitallyActive = 1,
    ActiveRole = 2, //IsImplicitlyActive
    ActivityParticipated = 3, //IsExplicitlyActive
}
export enum SortingFieldName {   
    None = 0,
    PersonId = 1,
    MemberName = 2,
    Age = 3,
    Phone = 4,
    Email = 5,
    ClubName = 6,
    SportName = 7,
    SportsNumber = 8,
    TrainingFeeName = 9,//...
    TeamName = 10,
    MobileNumber = 11
}
export enum SortingOrder {
    Ascending = 1,
    Descending = 2
}

