import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'trainingNumber' })
export class TrainingNumberPipe implements PipeTransform {
    transform(value): any {

        return value.toString().padStart(10, '0');

    }
}

@Pipe({ name: 'membershipNumber' })
export class MembershipNumberPipe implements PipeTransform {
    transform(value): any {

        return value.toString().padStart(8, '0');

    }
}

