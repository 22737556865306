import { Pipe, PipeTransform } from '@angular/core';
import { MediaType } from '../core/common-enums';
import { MediasModel } from '../components/medias/Medias.model';

@Pipe({ name: 'imageBuilder' })
export class ImageBuilderPipe implements PipeTransform {
    transform(value: any, args: any = null): any {
        const defaultImage = '/assets/images/default/no-image.png';

        if (value !== undefined && value.length > 0) {
            const image = value.filter(data => data.MediaTypeID.toString() === MediaType.Image.toString());
            if (image.length > 0 && image[0]['MediaPath'] !== '') {
                return image[0]['MediaPath'];
            } else {
                return defaultImage;
            }
        } else {
            return defaultImage;
        }
    }
}

@Pipe({ name: 'profileImageBuilder' })
export class ProfileImageBuilderPipe implements PipeTransform {
    transform(value: MediasModel[]): any {
        const defaultImage = '/assets/images/avatars/profile.jpg';

        if (value !== undefined && value.length > 0) {
            const image = value.filter(data => data.MediaTypeID.toString() === MediaType.Image.toString());
            if (image.length > 0 && image[0]['MediaPath'] !== '') {
                return image[0]['MediaPath'];
            } else {
                return defaultImage;
            }
        } else {
            return defaultImage;
        }
    }
}
