import { EnvModel } from "./env.model";
import { GlobalConstants } from "./global-constants";

const env = localStorage.getItem(GlobalConstants.CookieKeys.EnvConfig);
let envData = new EnvModel({});
if (env) {
    envData = new EnvModel(JSON.parse(atob(env)));
}
export const GlobalAPIConstants = {
    Host: envData.Host,
    Headers: {
        ContentTypes: {
            ApplicationOctetStream: {
                'Content-Type': 'application/octet-stream; charset=utf-8'
            },
            ApplicationJSON: { 'Content-Type': 'application/json' }
        },
        DeliveryChannelID: envData.DeliveryChannelID
    },
    URLParams: {
        PageNumber: 'pageNumber',
        SearchString: 'searchString',
        SortBy: 'sortBy'
    },
    HeaderValues: {
        APIVersion: "1.0",
        ApplicationJSON: "application/json",
        ApplicationOctetStream: "application/octet-stream",

        AppVersion: "1.0",

        OcpAPIM: envData.OcpAPIM,
        FedId: 0,
    },
    ShowVersion: envData.ShowVersion,
    Env: envData.Env,
    Ver: envData.ver + '-' + envData.buildVer,
    SentryKey: envData.SentryKey,
    PageSize: envData.PageSize,
    oAuth: envData.oAuth,
    GoogleMapAPIKey: envData.GoogleMapAPIKey,
    reCaptcha: envData.reCaptcha,
    NETSPaymentGatewayURL: envData.NETSPaymentGatewayURL,
    avtaleGiroSignUpURL: envData.avtaleGiroSignUpURL,
};
