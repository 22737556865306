export const locale = {
    lang: 'en-GB',
    data: {
        'red': 'Red',
        'pink': 'Pink',
        'purple': 'Purple',
        'deep-purple': 'Deep Purple',
        'indigo': 'Indigo',
        'blue': 'Blue',
        'light-blue': 'Light Blue',
        'cyan': 'Cyan',
        'teal': 'Teal',
        'green': 'Green',
        'light-green': 'Light Green',
        'lime': 'Lime',
        'yellow': 'Yellow',
        'amber': 'Amber',
        'orange': 'Orange',
        'deep-orange': 'Deep Orange',
        'brown': 'Brown',
        'grey': 'Grey',
        'blue-grey': 'Blue Grey',
        'dark': 'Dark',
        'white': 'White',
        'black': 'Black',
        'SelectColor': 'Select Color',
        'SuccessTitle': 'Success!',
        'ErrorTitle': 'Opps! Please try again',
        'InfoTitle': 'Info!',
        'WarningTitle': 'Warning!',
       
    }
};



