import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AlertComponent } from '@det/components/alert/alert/alert.component';
import { AlertModel } from '@det/core/common.model';
import { AlertEnum } from '@det/core/common-enums';
import { GlobalConstants } from '@det/core/global-constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HelperService {

  constructor(
    private matSnackBar: MatSnackBar
   ) { }

  displayAlert(alertData: AlertModel, callBack = null) {
      alertData.duration = ((alertData.type === AlertEnum.Info) || (alertData.duration && alertData.duration > GlobalConstants.idealAlertDuration)) ?
                            alertData.duration : GlobalConstants.idealAlertDuration;

      const alertAction = this.matSnackBar.openFromComponent(AlertComponent, {
        duration: alertData.duration,
        panelClass: 'matsnack-base',
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          type: alertData.type,
          message: alertData.message,
          buttonText: alertData.buttonText,
          withoutClose: alertData.withoutClose
        }
      });
      setTimeout(() => {
        if (alertAction) {
          alertAction.dismiss();
        }
      }, 10000);
      alertAction.onAction().subscribe(() => {
        if (callBack) {
          callBack();
        }
      });

      if (alertData.returnAlertObj) {
        return alertAction;
      }

  }

  displaySuccessMessage(message, duration = GlobalConstants.alertMinDuration) {
    const alertInfoData = new AlertModel({
      type : AlertEnum.Success,
      message,
      duration
    });
    this.displayAlert(alertInfoData); 
  }
  
  displayErrorMessage(message, duration = GlobalConstants.alertMinDuration) {
    const alertInfoData = new AlertModel({
      message,
      duration
    });
    this.displayAlert(alertInfoData); 
  }

  replaceHTMLTags(text, isEncode): Observable<string> {

    if (text) {
      if (isEncode) {
        text = text.replace(/\</g, "&lt;");   //for <
        text = text.replace(/\>/g, "&gt;");   //for >
      } else {
        if (text !== '') {
          text = text.replace(/\&gt;/g, ">");
          text = text.replace(/\&lt;/g, "<").toString();
        }
      }

    }
    return of(text);
  }

  
  customSort(key, order = 'asc') {
    return function (a: any, b: any) {
      if (!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }
}
