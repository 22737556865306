import { OAuthModel } from "./oauth.model";
export class EnvModel {
    public production: boolean;
    public hmr: boolean;
    public Host: string;
    public ShowVersion: boolean;
    public ver: string;
    public buildVer: number;
    public Env: string;
    public SentryKey: string;
    public GoogleMapAPIKey: string;
    public OcpAPIM: string;
    public PageSize: number;
    public NETSPaymentGatewayURL: string;
    public reCaptcha: string;
    public oAuth: OAuthModel;
    public DeliveryChannelID: string;
    public avtaleGiroSignUpURL: string;
    constructor(data) {
        this.production = data.production || true;
        this.hmr = data.hmr || false;
        this.Host = data.Host || "";
        this.ShowVersion = data.ShowVersion || true;
        this.ver = data.ver || "";
        this.buildVer = data.buildVer || "";
        this.Env = data.Env || "";
        this.SentryKey = data.SentryKey || null;
        this.GoogleMapAPIKey = data.GoogleMapAPIKey || "";
        this.OcpAPIM = data.OcpAPIM || "";
        this.PageSize = data.PageSize || 50;
        this.NETSPaymentGatewayURL = data.NETSPaymentGatewayURL || '';
        this.reCaptcha = data.reCaptcha || '';    
        this.DeliveryChannelID = data.DeliveryChannelID || '';
        this.oAuth = data.oAuth || new OAuthModel({});
        this.avtaleGiroSignUpURL = data.avtaleGiroSignUpURL || '';
    }
}


