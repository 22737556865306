import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DynamicTreeNode } from './../../app/main/content/apps/member/member.model';
import { AlertEnum, FilterTypeEnum, StatusEnum } from '@det/core/common-enums';

import { GlobalConstants } from "./global-constants";
import { DataSource } from '@angular/cdk/collections';

export class ToggleModel {
    ID: string;
    Status: number;
    constructor(data) {
        this.ID = data.ID || '';
        this.Status = data.Status || 0;
    }
}

export class ErrorModel {
    ErrorCode: number;
    ErrorMessage: string;

    constructor(data) {
        this.ErrorCode = data.ErrorCode || 0;
        this.ErrorMessage = data.ErrorCode || '';

    }
}
export class ResponseModel {
    ID: string;

    constructor(data) {
        this.ID = data.ID || '';
    }
}
export class ImageInfoModel {
    Base64String: string;
    Width: number;
    Height: number;
    FileName: string;

    constructor(base64String?: string, width?: number, height?: number) {
        this.Base64String = base64String;
        this.Width = width;
        this.Height = height;
    }
}

export class UploadImageResponseModel {
    URL: string;
    constructor(data) {
        this.URL = data.URL || '';
    }
}

export class TimeZoneModel {
    TimeZoneID: string;
    TimeZoneName: string;
    TimeZoneCode: string;
    TimeZoneOffsetFromUtc: number;
    HasDst: boolean;
    Status: number;
    constructor(data) {
        this.TimeZoneID = data.TimeZoneID || '';
        this.TimeZoneName = data.TimeZoneName || '';
        this.TimeZoneOffsetFromUtc = data.TimeZoneOffsetFromUtc || '';
        this.HasDst = data.HasDst || '';
        this.Status = data.Status || '';
    }
}


export class CountryModel {
    CountryID: string;
    CountryName: string;
    CountryCode: string;
    Status: number;
    constructor(data) {
        this.CountryID = data.CountryID || '';
        this.CountryName = data.CountryName || '';
        this.CountryCode = data.CountryCode || '';
        this.Status = data.Status || '';
    }
}


export class CurrencyModel {
    CurrencyID: string;
    CurrencyName: string;
    CurrencyCode: string;
    CurrencySymbol: string;

    CurrencyPrecision: number;
    CurrencyConversionFromUsd: number;
    CurrencyConversionTimestamp: string;
    Status: number;
    constructor(data) {
        this.CurrencyID = data.CurrencyID || '';
        this.CurrencyName = data.CurrencyName || '';
        this.CurrencyCode = data.CurrencyCode || '';
        this.CurrencySymbol = data.CurrencySymbol || '';
        this.CurrencyPrecision = data.CurrencyPrecision || '';
        this.CurrencyConversionFromUsd = data.CurrencyConversionFromUsd || '';
        this.CurrencyConversionTimestamp = data.CurrencyConversionTimestamp || '';
        this.Status = data.Status || '';
    }
}


export class PostalCodeTreeModel {
    Name: string;
    expandable: boolean;
    Level: number;
    Child: PostalCodeTreeModel[];
    constructor(data) {
        this.Name = data.Name || 0;
        this.expandable = data.expandable || false;
        this.Level = data.Level || 0;
        this.Child = data.Child || [];
    }
}




export class MediaResposen {
    FileName: string;
    MediaPathURL: string;
    constructor(data) {
        this.FileName = data.FileName || '';
        this.MediaPathURL = data.MediaPathURL || '';
    }
}

export class FilterModel {
    id: string;
    name: string;
    constructor(data) {
        this.id = data.id || '';
        this.name = data.name || '';
    }
}

export class HistoryModel {
    GUID: number;
    ModuleName: string;
    ActionGUID: string;
    ActionName: string;
    SubModule: string;
    URL: string;
    constructor(data) {
        this.GUID = data.GUID || '';
        this.ModuleName = data.ModuleName || '';
        this.ActionGUID = data.ActionGUID || '';
        this.ActionName = data.ActionName || '';
        this.SubModule = data.SubModule || '';
        this.URL = data.URL || '';
    }
}


// TODO: Remove when we add consumer module

export class ConsumerList {
    ConsumerProfileID: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Email: string;
    constructor(data) {
        this.ConsumerProfileID = data.ConsumerProfileID || '';
        this.FirstName = data.FirstName || '';
        this.LastName = data.LastName || '';
        this.Email = data.Email || '';
    }
}

export const CUSTOM_DATE_FORMAT = {
    parse: {
        dateInput: 'DD MMM YYYY',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD MMM YYYY',
        monthYearA11yLabel: 'MMM YYYY',
    },
};


export class BreadcrumbModel {
    URL: string;
    Label: string;
    ShowLink: boolean;
}

export class ShortcutLinkModel {
    url: string;
    logoLink: string;
    title: string;

    constructor(data) {
        this.url = data.url || '';
        this.logoLink = data.logoLink || '';
        this.title = data.title || '';
    }
}

export class AlertModel {
    type: number;
    message: string;
    buttonText: string;
    returnAlertObj: boolean;
    withoutClose: boolean;
    duration: number;

    constructor(data) {
        this.type = data.type || AlertEnum.Error;
        this.message = data.message || '';
        this.buttonText = data.buttonText || '';
        this.returnAlertObj = data.returnAlertObj || false;
        this.withoutClose = data.withoutClose || false;
        this.duration = (data.duration || data.duration === 0) ? data.duration : GlobalConstants.alertMinDuration;
    }
}

export interface FilterArrayModel {
    filterName: string;
    filterType: FilterTypeEnum;
    filterValues: FilterValuesModel[];
    clearValue?: boolean;
    filterApplied?: boolean;
    label: string;
    isAllowToCreate?: boolean;
    isAllowEditCategory?: boolean;
    isAllowViewCategory?: boolean;
    isLoading?: boolean;
    dataSource?: MatTreeNestedDataSource<DynamicTreeNode>;
    count?: number;
    displayCount?: boolean;
}
export interface FilterValuesModel {
    key: string;
    parentId?: string;
    keyToPassBack?: string;
    level?: number;
    value: string;
    yearFrom?: boolean;
    selected?: boolean;
    disabled?: boolean;
    label?: string;
    header?: string;
    hasValidation?: boolean;
    translationNotaRequired?: boolean;
    type?: string;
    isClickable?: boolean;
    count?: number;
}

