import { LocationsModel } from "@det/components/locations/Locations.model";
import { MediasModel } from "@det/components/medias/Medias.model";
import { GenderEnum } from "./common-enums";

export class LoginUserModel {
    UserName: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Gender: number;
    BirthDate: string;
    PhoneNumber: string;
    MobilePhone: string;
    Email: string;
    ContactStatus: number;
    UserTypeID: string;
    AccountID: string;
    DepartmentID: string;
    DepartmentName: string;
    AccountName: string;
    BusinessUnitID: string;
    BusinessUnitName: string;
    Modules: ModulesModel[];
    Locations: LocationsModel[];
    PhoneNumberCountryCode: string;
    MobilePhoneCountryCode: string;
    Medias: MediasModel[];
    NationalityID: string;
    NIFOrgID: number;
    ContactID: string;
    constructor(data) {
        this.UserName = data.UserName || '';
        this.FirstName = data.FirstName || '';
        this.MiddleName = data.MiddleName || '';
        this.LastName = data.LastName || '';
        this.Gender = data.Gender || GenderEnum.Unknown;
        this.BirthDate = data.BirthDate || '';
        this.PhoneNumber = data.PhoneNumber || '';
        this.MobilePhone = data.MobilePhone || '';
        this.Email = data.Email || '';
        this.BusinessUnitID = data.BusinessUnitID || '';
        this.BusinessUnitName = data.BusinessUnitName || '';
        this.ContactStatus = data.ContactStatus || '';
        this.UserTypeID = data.UserTypeID || '';
        this.DepartmentID = data.DepartmentID || '';
        this.DepartmentName = data.DepartmentName || '';
        this.AccountID = data.AccountID || '';
        this.AccountName = data.AccountName || '';
        this.Modules = data.Modules || new ModulesModel({});
        this.Locations = data.Locations || [];
        this.Medias = data.Medias || [];
        this.AccountName = data.AccountName || '';
        this.PhoneNumberCountryCode = data.PhoneNumberCountryCode || '+47';
        this.MobilePhoneCountryCode = data.MobilePhoneCountryCode || '+47';
        this.NationalityID = data.NationalityID || '';
        this.NIFOrgID = data.NIFOrgID || 0;
        this.ContactID = data.ContactID || '';
    }
}

export class ModulesModel {
    ModuleID: string;
    UIComponents: ModuleUIComponentsModel[];
    ActionRights: ModuleActionRightsModel[];
    constructor(data) {
        this.ModuleID = data.ModuleID || '';
        this.UIComponents = data.UIComponents || [];
        this.ActionRights = data.ActionRights || [];
    }
}

export class ModuleActionRightsModel {
    ActionRightID: string;
    constructor(data) {
        this.ActionRightID = data.ActionRightID || '';
    }

}
export class ModuleUIComponentsModel {
    UIComponentID: string;
    constructor(data) {
        this.UIComponentID = data.UIComponentID || '';
    }
}

