import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'TimeZoneFormat' })
export class TimezoneFormatPipe implements PipeTransform {
    transform(value: number): any {
        let formatTimezone = "";
        if (value !== undefined) {
            if (value < 0) {
                formatTimezone = value.toFixed(2);
            } else {
                formatTimezone = '+' + value.toFixed(2);
            }
            formatTimezone = formatTimezone.replace('.', ':');
        }
        return formatTimezone.trim();
    }
}
