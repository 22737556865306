import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalConstants } from './global-constants';

import { AuthService } from './auth.service';

@Injectable()
export class AuthenticationGuardGuard implements CanActivate {
  constructor(private loginService: AuthService, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn: boolean = this.loginService.allowToAccess;
    if (!isLoggedIn) {
      this.loginService.signOut();
      this.router.navigate([GlobalConstants.Routes.Signin]);
    }
    return true;
  }
}
