
import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConstants } from '../core/global-constants';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';


@Pipe({
    name: 'customDateTime',
    pure: false
})
export class DateTimePipe implements PipeTransform {
    languageSubscription: Subscription;
    constructor(private translateService: TranslateService) { }

    transform(value: any, args?: any): any {

        if (this.languageSubscription !== undefined) {
            this.languageSubscription.unsubscribe();
        }
        let lang = this.translateService.currentLang;
        this.languageSubscription = this.translateService.onLangChange.subscribe((event) => {
            lang = event ? event.lang : this.translateService.currentLang;
        });
        if (value !== undefined && value !== '') {
            let format = GlobalConstants.DateFormat;
            let offsetValue = '.000+0000';
            const explodeOffset = value.split('+');
            if (explodeOffset.length > 0) {
                value = explodeOffset[0];
                offsetValue = '';
            }
            if (typeof args !== typeof undefined) {
                format = args;
            }
            let mainDate: any = [];
            if (value) {
                mainDate = value.split('.');
                if (mainDate.length === 2) {
                    value = mainDate[0];
                }
            }
            if (lang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            if (this.languageSubscription !== undefined) {
                this.languageSubscription.unsubscribe();
            }
            return value ? moment.utc(value + offsetValue).local().format(format) : '';
        } else {
            if (this.languageSubscription !== undefined) {
                this.languageSubscription.unsubscribe();
            }
            return '';
        }
    }
}


@Pipe({
    name: 'customDate'
})
export class DatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any, args?: any): any {
        if (value !== undefined && value !== '') {
            let format = GlobalConstants.DateFormat;

            const explodeOffset = value.split('+');
            if (explodeOffset.length > 0) {
                value = explodeOffset[0];
            }

            if (typeof args !== typeof undefined) {
                format = args;
            }
            let mainDate: any = [];
            if (value) {
                mainDate = value.split('.');
                if (mainDate.length === 2) {
                    value = mainDate[0];
                }
            }
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value + '.000+0000').format(format) : '';
        } else {
            return '';
        }
    }

}

@Pipe({
    name: 'customDateWithDay'
})
export class DatePipeWithDay implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any, args?: any): any {
        if (value !== undefined && value !== '') {
            let format = GlobalConstants.DateFormatWithDay;

            const explodeOffset = value.split('+');
            if (explodeOffset.length > 0) {
                value = explodeOffset[0];
            }

            if (typeof args !== typeof undefined) {
                format = args;
            }
            let mainDate: any = [];
            if (value) {
                mainDate = value.split('.');
                if (mainDate.length === 2) {
                    value = mainDate[0];
                }
            }
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value + '.000+0000').format(format) : '';
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'customDateWithAMPM'
})
export class DatePipeWithAMPM implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any, args?: any): any {
        if (value !== undefined && value !== '') {
            let format = GlobalConstants.DateTimeFormatAMPM;

            const explodeOffset = value.split('+');
            if (explodeOffset.length > 0) {
                value = explodeOffset[0];
            }

            if (typeof args !== typeof undefined) {
                format = args;
            }
            let mainDate: any = [];
            if (value) {
                mainDate = value.split('.');
                if (mainDate.length === 2) {
                    value = mainDate[0];
                }
            }
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value + '.000+0000').format(format) : '';
        } else {
            return '';
        }
    }
}


@Pipe({
    name: 'customDateWithDayWithLocalTimeZone'
})
export class DatePipeWithDayWithLocalTimeZonePipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any): any {
        if (value !== undefined && value !== '') {
            const format = GlobalConstants.DateFormatWithDay;
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value).format(format) : '';
        } else {
            return '';
        }
    }
}


@Pipe({
    name: 'customDateWithAMPMLocalTimeZone'
})
export class CustomDateWithAMPMLocalTimeZonePipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any): any {
        if (value !== undefined && value !== '') {
            const format = GlobalConstants.DateTimeFormatAMPM;
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value).format(format) : '';
        } else {
            return '';
        }
    }
}
@Pipe({
    name: 'customDateWithTimeUTCtoLocalWithoutFormat'
})
export class DateWithCurrentTimeUTCLocalWithoutFormatPipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any): any {
        if (value !== undefined && value !== '') {
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value).local().format('YYYY-MM-DDTHH:mm:ss.SSS') : '';
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'customDateWithTimeLocalTimeZone'
})
export class CustomDateWithTimeLocalTimeZonePipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any): any {
        if (value !== undefined && value !== '') {
            const format = GlobalConstants.DateTimeFormat;
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value).format(format) : '';
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'customDate24Hours',
})
export class CustomDate24Hours implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any): any {
        if (value !== undefined && value !== '') {
            const format = GlobalConstants.TimeFormat;
            if (this.translateService.currentLang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            return value ? moment(value).format(format) : '';
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'customDateWithTime',
    pure: false,
})
export class DateWithCurrentTimePipe implements PipeTransform {
    languageSubscription: Subscription;

    constructor(private translateService: TranslateService) { }
    transform(value: any, args?: any): any {
        let lang = this.translateService.currentLang;
        this.languageSubscription = this.translateService.onLangChange.subscribe((event) => {
            lang = event ? event.lang : this.translateService.currentLang;
        });
        if (value !== undefined && value !== '') {
            let format = GlobalConstants.DateTimeFormat;
            if (lang === 'nb-NO') {
                format = GlobalConstants.DateTimeFormatNorway;
            }
            const explodeOffset = value.split('+');
            if (explodeOffset.length > 0) {
                value = explodeOffset[0];
            }
            if (typeof args !== typeof undefined) {
                format = args;
            }
            let mainDate: any = [];
            if (value) {
                mainDate = value.split('.');
                if (mainDate.length === 2) {
                    value = mainDate[0];
                }
            }

            if (lang === 'nb-NO') {
                moment.locale('nb');
            } else {
                moment.locale('en');
            }
            if (this.languageSubscription !== undefined) {
                this.languageSubscription.unsubscribe();
            }
            return value ? moment.utc(value).format(format) : '';
        } else {
            if (this.languageSubscription !== undefined) {
                this.languageSubscription.unsubscribe();
            }
            return '';
        }
    }
}