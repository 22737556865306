import { GenderEnum, StatusEnum, ThemeEnum } from "@det/core/common-enums";

import { LocationsModel } from "@det/components/locations/Locations.model";
import { ModulesModel } from "@det/core/user-model";
import { MediasModel } from "@det/components/medias/Medias.model";
import { GlobalConstants } from "@det/core/global-constants";

export class LoginModel {
    UserName: string;
    Password: any;
    RememberMe: boolean;
    Device: DeviceModel;
}

export class DeviceModel {
    PushToken: string;
    Model: string;
    Version: string;
    Name: string;
    OS: string;
    OSVersion: string;
    UUID: string;
    Type: number;
    IsBluetoothOn: boolean;
    IsLocationServicesOn: boolean;

    constructor(data) {
        this.PushToken = data.PushToken || '';
        this.Model = data.Model || '';
        this.Version = data.Version || '';
        this.Name = data.Name || '';
        this.OS = data.OS || '';
        this.OSVersion = data.OSVersion || '';
        this.UUID = data.UUID || '';
        this.Type = data.Type || '';
        this.IsBluetoothOn = data.IsBluetoothOn || '';
        this.IsLocationServicesOn = data.IsLocationServicesOn || '';
    }
}

export class AcquireTokenModel {
    Token: string;
    ConsumerProfileID: string;
    UserInfo: UserInfoModel;
    constructor(data) {
        this.Token = data.Token || '';
        this.ConsumerProfileID = data.ConsumerProfileID || '';
        this.UserInfo = data.UserInfo || new UserInfoModel({});
    }
}

export class AuthorizationTokenModel {
    RememberMe: boolean;
    ConsumerProfileID: string;
    ProUserID: string;
    constructor(data) {
        this.RememberMe = data.RememberMe || false;
        this.ConsumerProfileID = data.ConsumerProfileID || '';
        this.ProUserID = data.ProUserID || '';
    }
}

export class AuthorizationResponseModel {
    Token: string;
    ExpiresOn: string;
    constructor(data) {
        this.Token = data.Token || '';
        this.ExpiresOn = data.ExpiresOn || '';
    }
}
export class UserSettingModel {
    UserSettingKey: string;
    UserSettingData: string;
    constructor(data) {
        this.UserSettingKey = data.UserSettingKey || '';
        this.UserSettingData = data.UserSettingData || '';
    }
}


export class UserInfoModel {
    ContactID: string;
    UserID: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    UserName: string;
    Email: string;
    Gender: number;
    BirthDate: string;
    PhoneNumber: string;
    MobilePhone: string;
    ProUsers: ProUsersModel[];
    Locations: LocationsModel[];
    Medias: MediasModel[];
    ExternalID: string;
    IsValidated: boolean;
    IsUserNameSet: boolean;
    PhoneNumberCountryCode: string;
    MobilePhoneCountryCode: string;
    NationalityID: string;
    Profiles: any;
    ThemeId: number;
    Name: string;
    Height: string;
    Weight: string;
    InvoiceEmail: string;
    Guardian1Name: string;
    Guardian1Email: string;
    Guardian1Mobile: string;
    Guardian2Name: string;
    Guardian2Email: string;
    Guardian2Mobile: string;
    constructor(data) {
        this.ContactID = data.ContactID || '';
        this.UserID = data.UserID || '';
        this.FirstName = data.FirstName || '';
        this.MiddleName = data.MiddleName || '';
        this.LastName = data.LastName || '';
        this.UserName = data.UserName || '';
        this.Email = data.Email || '';
        this.Gender = data.Gender || GenderEnum.Unknown;
        this.BirthDate = data.BirthDate || '';
        this.PhoneNumber = data.PhoneNumber || '';
        this.MobilePhone = data.MobilePhone || '';
        this.ProUsers = data.ProUsers || [];
        this.Locations = data.Locations || [];
        this.Medias = data.Medias || [];
        this.ExternalID = data.ExternalID || '';
        this.IsValidated = data.IsValidated || false;
        this.IsUserNameSet = (data.IsUserNameSet === "1" || data.IsUserNameSet === true) ? true : false;
        this.NationalityID = data.NationalityID || GlobalConstants.NorwayCountryID;
        this.PhoneNumberCountryCode = data.PhoneNumberCountryCode || '+47';
        this.MobilePhoneCountryCode = data.MobilePhoneCountryCode || '+47';
        this.Profiles = data.Profiles || [];
        this.ThemeId = data.ThemeId || ThemeEnum.LightTheme;
        this.Name = data.Name || '';
        this.Height = data.Height || '';
        this.Weight = data.Weight || '';
        this.InvoiceEmail = data.InvoiceEmail || '';
        this.Guardian1Name = data.Guardian1Name || '';
        this.Guardian1Email = data.Guardian1Email || '';
        this.Guardian1Mobile = data.Guardian1Mobile || '';
        this.Guardian2Name = data.Guardian2Name || '';
        this.Guardian2Email = data.Guardian2Email || '';
        this.Guardian2Mobile = data.Guardian2Mobile || '';

    }
}

export class ProUsersModel {
    ProUserID: string;
    UserTypeID: string;
    AccountID: string;
    AccountName: string;
    BusinessUnitID: string;
    BusinessUnitName: string;
    RbacTemplateID: string;
    RbacTemplateName: string;
    DepartmentID: string;
    DepartmentName: string;
    Modules: ModulesModel[];
    Status: StatusEnum;
    StatusBoolean: boolean;
    IsModified: boolean;
    IsDeleted: boolean;
    NIFOrgID: number;
    Name: string;
    constructor(data) {
        this.ProUserID = data.ProUserID || '';
        this.UserTypeID = data.UserTypeID || '';
        this.AccountID = data.AccountID || '';
        this.AccountName = data.AccountName || '';
        this.BusinessUnitID = data.BusinessUnitID || '';
        this.BusinessUnitName = data.BusinessUnitName || '';
        this.RbacTemplateID = data.RbacTemplateID || '';
        this.RbacTemplateName = data.RbacTemplateName || '';
        this.DepartmentID = data.DepartmentID || '';
        this.DepartmentName = data.DepartmentName || '';
        this.Modules = data.Modules || [];
        this.Status = data.Status || StatusEnum.Draft;
        this.StatusBoolean = data.StatusBoolean || false;
        this.IsModified = data.IsModified || false;
        this.IsDeleted = data.IsDeleted || false;
        this.NIFOrgID = data.NIFOrgID || 0;
        this.Name = data.Name || '';
    }
}

export interface VerifyLoggedInUserModel {
    InvitationEmailPersonId: number;
    LoggedInUserBuyPassId: number;
}
export interface VerifyLoggedInUserResponseModel {
    IsValidUser: boolean;
}

export class SignUpModel {
    Email: string;
    Password: string;
    ConsentOriginID: string;
    Device: DeviceModel;
    constructor(data){
        this.Email = data.Email || '';
        this.Password = data.Password || '';
        this.ConsentOriginID = data.ConsentOriginID || '';
        this.Device = data.Device || new DeviceModel({});
    }
}

