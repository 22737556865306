export const locale = {
    lang: 'nb-NO',
    data: {
        'PageTitle': 'Logg inn | NIF',
        'ForgetPasswordPageTitle': 'Glemt passord | NIF',
        'SingUpPageTitle': 'Registrer deg | NIF',
        'WELCOME': 'Velkommen til NIF!',
        'loginwithIDserver': 'Logg inn med idrettens ID',
        'LOGINTOYOURACCOUNT': 'LOGG INN PÅ DIN KONTO',
        'RememberMe': 'Husk meg',
        'ForgotPassword': 'Glemt passord?',
        'Login': 'Logg inn',
        'Emailisrequired': 'E-post er påkrevd',
        'ValidEmail': 'Vennligst skriv inn en gyldig e-post adresse',
        'PasswordRequired': 'Passord er påkrevd',
        'login': 'Logg inn',
        'Password': 'Passord',
        'Email': 'E-post',
        'UserName': 'Brukernavn',
        'UserNameisrequired': 'Brukernavn er påkrevd',
        'UserNameAlreadyExists': 'Brukernavnet eksisterer allerede. Vennligst velg unik en.',
        'InvalidUserName': 'Ugyldig brukernavn',
        'RecoverPassword': 'GJENOPPRETT PASSORDET DITT',
        'SendResetLink': 'SEND TILBAKESTILLENDE LINK',
        'GoBackLogin': 'Gå tilbake til innlogging',
        'UsersNotAuthorized': 'Bruker ikke autorisert',
        'B2CUserNotAllowed': 'B2C brukere har ikke tilgang til denne portalen',
        'OR': 'ELLER',
        'DontHaveAccount': 'Mangler en konto?',
        'CreateAccount': 'Opprett en bruker',
        'ForgetPassword': 'Glemt passord?',
        'CreateAnAccount': 'OPPRETT EN BRUKER',
        'ConfirmPassword': 'Passord (Bekreft)',
        'ConsentOriginID': 'Samtykke ID',
        'ConsentOriginIDRequired': 'Samtykke ID er påkrevd',
        'ConfirmPasswordMatch': 'Passordene er ikke like',
        '_2002': 'Bruker finnes allerede',
        'SomethingWentWrong': 'Noe gikk galt',
        'AccountCreated': 'Konto opprettet',
        'SessionExpired': 'Økten er utløpt',
        'PushTokenRequired': 'Pushvarsel avslått',
        'PushTokenRequiredText': 'Pushvarsel er blitt avslått av bruker eller nettleseren støttes ikke. Det ville være fint hvis du gir tilgang til å varsle, selv om du ikke er på denne portalen',
        'SetPasswordTitle': 'Angi passord',
        'ResetPasswordTitle': 'Tilbakestill passord',
        'PasswordSet': 'Ditt passord er lagret. Vennligst logg inn med ditt nye passord .',
        'Passwordreset': 'Passordet ditt har blitt tilbakestilt. Vennligst logg inn med ditt nye passord.',
        'PasswordForgot': 'Du vil motta en e-post for gjenoppretting av passord.',
        'UserNotFound': 'Bruker ikke funnet. Vennligst sjekk brukernavnet du skrev inn.',
        'UnableToGetUserInfo': 'ystemet får ikke tak i informasjonen din. Vennligst prøv igjen senere.',
        'TokenExpiredOrInvalidEmail': 'Linken er utløpt.',
        'TokenExpired': 'Linken er utløpt.',
        'InvalidEmailID': 'Angitt e-post adresse er feil.',
        'ConsumerProfileNotFound': 'Forbrukerprofil ikke funnet',
        'InValidLogin': 'Uautorisert innlogging forsøkt! Prøv å logge på igjen med riktig brukernavn/passord.',
        'POSTLOGIN': {
            'PageTitle': 'Vennligst velg profil | NIF',
            'Welcome': 'Velkommen',
            'Intro': 'Vi har funnet {{totalProfiles}} profiler knyttet til din brukerkonto.' +
                'Vennligst velg en av disse. Du kan bytte profil etter at du har logget inn ved å klikke på Min Profil øverst til høyre.',
            'NotYou': 'Ikke deg?',
            'Signout': 'Logg ut',
            'Unknown': 'Ukjent',
            'Renewed': 'Fornyet',
            'Expired': 'Utgått',
            'Cancelled': 'Kansellert',
            'Active': 'Aktiv',
            'InActive': 'Inaktiv',
            'PleaseWait': 'Vennligst vent... | NIF',
            'Pending': 'Ventende',
            'MainMember': 'Linket medlemskap',
            'SentForApproval': 'Send For Approval',
            'Approved': 'Approved',
            'LinkedMemberShipWith': 'Linket medlemskap med',
            'Consent': 'Gi samtykke',
            'SaveConsent': 'Lagre samtykke',
            'updateConsentWayText': 'Innstillingene kan endres i profilen din.'
        },
        'MEMBERSHIP': {
            'PageTitlePublic': 'Søknad om medlemskap',
            'ThankYouMessage': 'Takk for din registrering'
        },
        "VOLUNTEER": {
            'PageTitlePublic': 'Registrer deg som frivillig',
            'PageTitle': 'Frivillig'
        },
        "SPONSORSHIP": {
            'PageTitle': 'Sponsorregistrering'
        }
    }
};



