export const MessageMapping = {
    ACCOUNT: {
        503: 'ACCOUNT.ServiceNotAvailable',
        4004: 'ACCOUNT.InvalidAccountID'
    },
    ACTIVITY: {
        503: 'ACTIVITY.ServiceNotAvailable',
        25007: 'ACTIVITY.NotAllowToUpdate'
    },
    BUYMEMBERSHIP: {
        1004: 'BUYMEMBERSHIP.InvoiceAlreadyInQueue',
        1008: 'BUYMEMBERSHIP.DefaultSettlementAccountNotSet',
        3001: 'BUYMEMBERSHIP.MembershipNotFoundForPeriod',
        3005: 'BUYMEMBERSHIP.ConsentError',
        3006: 'BUYMEMBERSHIP.PaymentAPIFailed'
    },
    CART : {
        1001: 'CART.IncompleteUserProfile',
        1004: 'CART.InvoiceAlreadyInQueue',
        1007: 'CART.OrderConfigurationNotInitialised',
        1008: 'CART.DefaultSettlementAccountNotSet',
        1013: 'CART.NIFIntegrationExceptionFromIntegrationClient',
        1035: 'CART.MissingBuypassMerchantDetailIDInvoice',
        2009: 'CART.InvalidpersonID',
        3005: 'CART.ConsentError',
        3006: 'CART.PaymentAPIFailed',
        9000: 'CART.InvalidProductInCart',
        9017: 'CART.InvalidCartId',
        9023: 'CART.InvalidNextInvoiceDate',
        9024: 'CART.PaymentAccountRequired',
        9025: 'CART.MissingPaymentAccount',
        13012: 'CART.InvalidProductForRecruitment',
        13013: 'CART.RecruitmentPointsNotEnough',
        30001: 'CART.MembershipNotFoundForSpecificPeriodOfDate'
    },
    CASE : {
        503: 'CASE.ServiceNotAvailable'
    },
    COMMON : {
        503: 'COMMON.ServiceNotAvailable',
        2009: 'COMMON.InvalidpersonID',
        23000: 'COMMON.ClubDoNotHavePaymentInformation',
        23003: 'COMMON.PaymentValidationFailed',
        23006: 'COMMON.NIFPaymentValidationFailed',
        30000: 'COMMON.ClubNotOnboarded',
        30001: 'COMMON.TokenInValid'
    },
    ESHOP : {
        503: 'ESHOP.ServiceNotAvailable',
        9012: 'ESHOP.CartCreateError',
        9013: 'ESHOP.InvalidProductVariant',
        9014: 'ESHOP.InvalidProductVariantVersion',
        9015: 'ESHOP.InvalidProductOption',
        9016: 'ESHOP.InvalidProductOptionVersion',
    },
    EVENT : {
        503: 'EVENT.ServiceNotAvailable',
        16000: 'EVENT.EventNotFound',
        16002: 'EVENT.EventStatusChangeNotAllowed',
        16003: 'EVENT.EventParticipantNotFound',
        16004: 'EVENT.EventSeatNotAvailable',
    },
    GRADE : {
        503: 'GRADE.ServiceNotAvailable'
    },
    GENERALLEDGER : {
        503: 'GENERALLEDGER.ServiceNotAvailable',
        10015: 'GENERALLEDGER.BankAccountNotFound',
        26000: 'GENERALLEDGER.InvalidInvoiceID',
        26001: 'GENERALLEDGER.InvalidRegisterPaymentRequest',
        26002: 'GENERALLEDGER.InvalidAmountRegisterPayment',
        26003: 'GENERALLEDGER.InvoiceEntryMissinginGeneralLedger',
        26004: 'GENERALLEDGER.InvalidCreditNoteRequest',
        26005: 'GENERALLEDGER.InvoiceEntryMissingGeneralLedger',
        26006: 'GENERALLEDGER.EmailNotConfigured',
        26007: 'GENERALLEDGER.NAVMessageNotFound',
        26008: 'GENERALLEDGER.InSufficientPrePaymentBalance',
    },
    MEMBERSHIP : {
        503: 'MEMBERSHIP.ServiceNotAvailable',
        2004: 'MEMBERSHIP.ConsumerProfileNotFound',
        2008: 'MEMBERSHIP.ContactNotFound',
        4019: 'MEMBERSHIP.ClubIsNotAvtaleGiroEnabled',
        9021: 'MEMBERSHIP.CartIsEmpty',
        10004: 'MEMBERSHIP.MembershipAlreadyExists',
        10015: 'MEMBERSHIP.BankDetailNotFound',
        10016: 'MEMBERSHIP.AvtalegiroAmountLimitNotFoundClub',
        10019: 'MEMBERSHIP.CanNotSendForApproval',
        10023: 'MEMBERSHIP.DuplicateTryOutNotAllowed',
        10026: 'MEMBERSHIP.AvtalGiroMandatory',
        10029: 'MEMBERSHIP.InvoiceNotPaid',
        10030: 'MEMBERSHIP.OnlyAutoRenewAllowed',
        10032: 'MEMBERSHIP.OnboardingDateGraterThenCurrentDate',
        10044: 'MEMBERSHIP.InvalidNIFMembershipID',
        13021: 'MEMBERSHIP.MembershipMissingInCart',
        23001: 'MEMBERSHIP.TransactionError',
        10007: 'MEMBERSHIP.InvalidIndividualMember',
        30001: 'MEMBERSHIP.TokenInValid',
    },
    MYINVOICES : {
        1004: 'MYINVOICES.InvoiceAlreadyInQueue',
        3005: 'MYINVOICES.ConsentError',
        3006: 'MYINVOICES.PaymentAPIFailed',
    },
    PRODUCT : {
        503: 'PRODUCT.ServiceNotAvailable',
    },
    RECRUITMENT: {
        503: 'RECRUITMENT.ServiceNotAvailable',
        10004: 'RECRUITMENT.AlredyExist',
    },
    SPONSORSHIPMANAGEMENT: {
        503: 'SPONSORSHIPMANAGEMENT.ServiceNotAvailable',
        4004: 'SPONSORSHIPMANAGEMENT.NotAllowToUpdate',
        15000: 'SPONSORSHIPMANAGEMENT.InvalidSponsorRequest',
        15008: 'SPONSORSHIPMANAGEMENT.DuplicateSponsorRequest',
    },
    TEAMS: {
        503: 'TEAMS.ServiceNotAvailable'
    },
    ORDER: {
        503: 'ORDER.ServiceNotAvailable',
        9007: 'ORDER.OrderStatusChangeNotAllowed'
    },
    USER: {
        2007: 'USER.ServiceNotAvailable'
    }
};


