import {
    NameBuilderPipe,
} from './nameBuilder.pipe';
import {
    CustomDate24Hours,
    CustomDateWithAMPMLocalTimeZonePipe,
    CustomDateWithTimeLocalTimeZonePipe,
    DatePipe,
    DatePipeWithAMPM,
    DatePipeWithDay,
    DatePipeWithDayWithLocalTimeZonePipe,
    DateTimePipe,
    DateWithCurrentTimeUTCLocalWithoutFormatPipe,
    DateWithCurrentTimePipe
} from './datetime.pipe';
import { ImageBuilderPipe, ProfileImageBuilderPipe } from './imageBuilder.pipe';
import { KeysPipe, KeysPipeFilter } from './keys.pipe';
import { MembershipNumberPipe, TrainingNumberPipe } from './generateNumber.pipe';

import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { CustomCurrencyPipe } from './currency.pipe';
import { FilterPipe } from './filter.pipe';
import { GenerateFourDecimalAndReplaceDotToComaPipe } from './generateFourDecimalAndReplaceDotToComa.pipe';
import { GenerateTwoDecimalAndReplaceDotToComaPipe } from './generateTwoDecimalAndReplaceDotToComa.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HasValueExistspipe } from './valueFindInArray.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { InitialsPipe } from './initial.pipe';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { TimezoneFormatPipe } from './common.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        KeysPipeFilter,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DateTimePipe,
        DatePipe,
        ImageBuilderPipe,
        ProfileImageBuilderPipe,
        NameBuilderPipe,
        InitialsPipe,
        TimezoneFormatPipe,
        GenerateTwoDecimalAndReplaceDotToComaPipe,
        GenerateFourDecimalAndReplaceDotToComaPipe,
        TrainingNumberPipe,
        MembershipNumberPipe,
        DatePipeWithDay,
        DatePipeWithAMPM,
        HasValueExistspipe,
        SafeHtmlPipe,
        CustomDateWithTimeLocalTimeZonePipe,
        CustomDateWithAMPMLocalTimeZonePipe,
        DatePipeWithDayWithLocalTimeZonePipe,
        DateWithCurrentTimeUTCLocalWithoutFormatPipe,
        CustomCurrencyPipe,
        ShortNamePipe,
        CustomDate24Hours,
        DateWithCurrentTimePipe,
    ],
    imports: [],
    providers: [ShortNamePipe],
    exports: [
        KeysPipe,
        KeysPipeFilter,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DateTimePipe,
        DatePipe,
        ImageBuilderPipe,
        ProfileImageBuilderPipe,
        InitialsPipe,
        NameBuilderPipe,
        TimezoneFormatPipe,
        GenerateTwoDecimalAndReplaceDotToComaPipe,
        GenerateFourDecimalAndReplaceDotToComaPipe,
        TrainingNumberPipe,
        MembershipNumberPipe,
        DatePipeWithDay,
        DatePipeWithAMPM,
        HasValueExistspipe,
        SafeHtmlPipe,
        CustomDateWithTimeLocalTimeZonePipe,
        CustomDateWithAMPMLocalTimeZonePipe,
        DatePipeWithDayWithLocalTimeZonePipe,
        DateWithCurrentTimeUTCLocalWithoutFormatPipe,
        CustomCurrencyPipe,
        ShortNamePipe,
        CustomDate24Hours,
        DateWithCurrentTimePipe,
    ]
})
export class PipesModule {
}
