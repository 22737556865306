
export class OAuthModel {
    public Host: string;
    public clientId: string;
    public dummyClientSecret: string;
    public scope: string;
    public postLogoutRedirectUri: string;

    constructor(data) {
        this.Host = data.Host || "";
        this.clientId = data.clientId || "";
        this.scope = data.scope || "";
        this.dummyClientSecret = data.dummyClientSecret || "";
        this.postLogoutRedirectUri = data.postLogoutRedirectUri || "";
    }
}