<router-outlet></router-outlet>
<!-- <div class="createButtonShortcut" *ngIf="showFabButton"> -->
<div class="createButtonShortcut" *ngIf="false">
    <button mat-fab [matMenuTriggerFor]="shortcutMenu" (menuOpened)="isMenuOpen = true" (menuClosed)="isMenuOpen = false" aria-label="Create menu">
            <mat-icon>add</mat-icon>
            <!-- <mat-icon *ngIf="isMenuOpen">clear</mat-icon> -->
        </button>
</div>

<mat-menu #shortcutMenu="matMenu" class="shortcutMenu-container" yPosition="above" xPosition="before">

</mat-menu>