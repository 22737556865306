import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { CustomCookieService } from './cookie.service';
import { AuthenticationGuardGuard } from './authentication-guard.guard';
import { AnonymousGuardGuard } from './anonymous-guard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth.service';
import {HttpClientModule} from "@angular/common/http";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [CustomCookieService, HttpService, AuthenticationGuardGuard, AnonymousGuardGuard, CookieService, AuthService ],
  exports: []
})
export class CoreModule { }
