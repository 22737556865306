import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hasValueExists' })
export class HasValueExistspipe implements PipeTransform {
    transform(value: string, args: any[]): any {
        if (value !== '' && value !== undefined && args !== undefined && args.length > 0) {
            const hasValue = args.findIndex(data => data === value);
            return (hasValue !== -1) ? true : false;
        } else {
            return false;
        }
    }
}

