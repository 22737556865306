import { Directive, DoCheck, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[passwordField]'
})
export class PasswordDirective implements OnInit {
    private element: HTMLInputElement;
    private _shown = false;

    constructor(private elRef: ElementRef) {
        this.element = elRef.nativeElement;
    }

    ngOnInit() {
        this.setup();
    }

    setup() {
        const parent = this.elRef.nativeElement.parentNode;
        const span = document.createElement('i');
        span.setAttribute('class', 'material-icons value-color clickable passwordIcon');
        span.innerText = 'visibility';
        span.addEventListener('click', (event) => {
            this.toggle(span);
        });
        if (parent.querySelector('i') === null) {
            parent.appendChild(span);
        }
    }

    toggle(span: HTMLElement) {
        this._shown = !this._shown;
        if (this._shown) {
            this.elRef.nativeElement.setAttribute('type', 'text');
            span.innerText = 'visibility_off';
        } else {
            this.elRef.nativeElement.setAttribute('type', 'password');
            span.innerText = 'visibility';
        }
    }

}