import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { FUSE_CONFIG, ConfigService } from './services/config.service';
import { CopierService } from './services/copier.service';
import { MatchMediaService } from './services/match-media.service';
import { MatSidenavHelperService } from './directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import { NavigationService } from './components/navigation/navigation.service';
import { SidebarService } from './components/sidebar/sidebar.service';
import { SplashScreenService } from './services/splash-screen.service';
import { TranslationLoaderService } from './services/translation-loader.service';
import { CoreModule } from './core/core.module';
import { PostalCodeService } from './services/postal-code.service';
import { CommonService } from './services/common.service';
import { HelperService } from './services/helper.service';
import { ErroHandlerService } from './services/error-handler.service';
import { AlertModule } from '@det/components/alert/alert.module';
import { AlertComponent } from '@det/components/alert/alert/alert.component';


@NgModule({
    entryComponents: [AlertComponent],
    providers: [
        ConfigService,
        CopierService,
        MatchMediaService,
        MatSidenavHelperService,
        NavigationService,
        SidebarService,
        SplashScreenService,
        TranslationLoaderService,
        PostalCodeService,
        CommonService,
        HelperService,
        ErroHandlerService
    ],
    imports: [CoreModule,AlertModule]

})
export class Module {
    constructor(@Optional() @SkipSelf() parentModule: Module) {
        if (parentModule) {
            throw new Error('Module is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders<Module> {
        return {
            ngModule: Module,
            providers: [
                {
                    provide: FUSE_CONFIG,
                    useValue: config
                },
                CommonService
            ]
        };
    }
}
