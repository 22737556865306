export const locale = {
    lang: 'nb-NO',
    data: {
        'SystemUser': 'Systemadministrator',
        'OrganizationUser': 'Organisasjonsadministrator',
        'BusinessUser': 'Forbundsadministrator',
        'AccountUser': 'Klubbadministrator',
        'DepartmentUser': 'Avdelingsbruker'
    }
};



