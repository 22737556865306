<div class="alert-box" fxLayout="row" fxLayoutAlign="start streach">
  <div class="alert-symbol " fxLayout="row" fxLayoutAlign="center center">
    <img class="logo {{colorClass}}" [src]="icon" alt="menu_item" />
  </div>
  <div class="alert-summary" fxLayout="column" fxLayoutAlign="start start">
    <div class="alert-title {{colorClass}}">{{title}}</div>
    <div class="alert-sub-title">
      {{message}}
      <span class="action-button" (click)="onButtonClick()">{{buttonText}}</span>
    </div>
  </div>
  <img class="close-icon" alt="Close" [src]="closeIcon" (click)="onClose()" *ngIf="displayCloseButton" />
</div>