import {
  Directive,
  Input,
  OnInit,
  HostListener,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

import { MatchMediaService } from '../../services/match-media.service';
import { MatSidenavHelperService } from './fuse-mat-sidenav.service';

@Directive({
  selector: '[fuseMatSidenavHelper]',
})
export class MatSidenavHelperDirective implements OnInit, OnDestroy {
  matchMediaSubscription: Subscription;
  @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
  @Input('fuseMatSidenavHelper') id: string;
  @Input('mat-is-locked-open') matIsLockedOpenBreakpoint: string;

  constructor(
    private fuseMatSidenavService: MatSidenavHelperService,
    private fuseMatchMedia: MatchMediaService,
    private observableMedia: MediaObserver,
    private matSidenav: MatSidenav
  ) {}

  ngOnInit() {
    this.fuseMatSidenavService.setSidenav(this.id, this.matSidenav);

    if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
      this.isLockedOpen = true;
      this.matSidenav.mode = 'side';
      this.matSidenav.toggle(true);
    } else {
      this.isLockedOpen = false;
      this.matSidenav.mode = 'over';
      this.matSidenav.toggle(false);
    }

    this.matchMediaSubscription = this.fuseMatchMedia.onMediaChange.subscribe(
      () => {
        if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
          this.isLockedOpen = true;
          this.matSidenav.mode = 'side';
          this.matSidenav.toggle(true);
        } else {
          this.isLockedOpen = false;
          this.matSidenav.mode = 'over';
          this.matSidenav.toggle(false);
        }
      }
    );
  }

  ngOnDestroy() {
    this.matchMediaSubscription.unsubscribe();
  }
}

@Directive({
  selector: '[fuseMatSidenavToggler]',
})
export class MatSidenavTogglerDirective {
  @Input('fuseMatSidenavToggler') id;

  constructor(private fuseMatSidenavService: MatSidenavHelperService) {}

  @HostListener('click')
  onClick() {
    this.fuseMatSidenavService.getSidenav(this.id).toggle();
  }
}
