import * as Raven from 'raven-js';

import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Module } from '@det/det.module';
import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppCustomPreloader } from './app-routing-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { GlobalAPIConstants } from '@det/core/global-api-constants';
import { GlobalConstants } from '@det/core/global-constants';
import { HttpClientModule } from '@angular/common/http';
import { MainModule } from './main/main.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ThemeService } from '@det/services/theme.service';

import { AuthenticationGuardGuard } from '@det/core/authentication-guard.guard';

Raven.config(GlobalAPIConstants.SentryKey, {
  environment: GlobalAPIConstants.Env
}).install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError || err);
  }
}


const appRoutes: Routes = [

  // preload dashboard module to prevent UI misBehaviour after selection of particular profile from postLogin((it's takes some time to load dashboard)
  {
    path: GlobalConstants.Routes.apps + '/members',
    canActivate: [AuthenticationGuardGuard],
    data: { preload: true },
    loadChildren: () => import('./main/content/apps/member/member.module').then(m => m.MemberModule)
  },
  {
    path: GlobalConstants.Routes.Dashboard,
    canActivate: [AuthenticationGuardGuard],
    redirectTo: GlobalConstants.Routes.apps + '/members',
  },
  {
    path: GlobalConstants.Routes.apps,
    canActivate: [AuthenticationGuardGuard],
    loadChildren: () => import('./main/content/apps/apps.module').then((m) => m.AppsModule)
  },
  {
    path: GlobalConstants.Routes.auth,
    loadChildren: () => import('./main/content/auth/login.module').then(m => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: GlobalConstants.Routes.auth + '/' + GlobalConstants.Routes.Signin,
  }
];
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MainModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    RouterModule.forRoot(appRoutes, { preloadingStrategy: AppCustomPreloader }),
    Module,
    TranslateModule.forRoot(),
  ],
  providers: [AppCustomPreloader, { provide: ErrorHandler, useClass: RavenErrorHandler }, ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
