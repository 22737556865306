import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConstants } from '@det/core/global-constants';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
    transform(value): any {
        let finalAmount = '';
        if (value !== undefined && value !== null && value !== '') {
            finalAmount = value.replace(/,/g, ' ');
            let temp = value.replace(/kr. /g, '');
            temp = temp.replace(/,/g, '');
            const tempNum = Number(temp);

            // 23 % 1 = 0
            // 23.5 % 1 = 0.5
            if (tempNum % 1 !== 0) {
                const dotIndex = value.lastIndexOf(".");
                const firstPart = finalAmount.substr(0, dotIndex);
                const lastPart = finalAmount.substr(dotIndex + 1);
                finalAmount = firstPart + ',' + lastPart;
            }
            else {
                const dotIndex = value.lastIndexOf(".00");
                const firstPart = finalAmount.substr(0, dotIndex);
                finalAmount = firstPart;
            }
        }
        return '<span class="currencyFormat">' +
            '<span class="currencyAmount">' + finalAmount + '</span>' +
            '</span>';
    }
}
