import { Pipe, PipeTransform } from '@angular/core';
import { UserInfoModel } from 'app/main/content/auth/sign-in/login-model';

@Pipe({ name: 'nameBuilder' })
export class NameBuilderPipe implements PipeTransform {
    transform(value, args: any = null): any {

        let name = "";
        if (value !== undefined) {
            if (value.FirstName !== undefined && value.FirstName !== null && value.FirstName !== "") {
                name = value.FirstName;
            }
            if (value.MiddleName !== undefined && value.MiddleName !== null && value.MiddleName !== "") {
                name = name + ' ' + value.MiddleName;
            }
            if (value.LastName !== undefined && value.LastName !== null && value.LastName !== "") {
                name = name + ' ' + value.LastName;
            }
            if ((value.FirstName === undefined || value.FirstName === "" || value.FirstName === null) &&
                (value.LastName === "" || value.LastName === undefined || value.LastName === null)) {
                if (value.UserName !== undefined && value.UserName !== null && value.UserName !== '') {
                    name = value.UserName;
                } else {
                    name = value.Email;
                }
            }
        }
        if (name !== undefined) {
            return name.trim();
        } else {
            return name;
        }

    }
}