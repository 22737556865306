import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@det/core/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  isLoggedIn = false;
  constructor(private loginService: AuthService, 
            private router: Router,
    ) {
    
    router.events.subscribe(
      (event) => {
          if (event instanceof NavigationEnd) {
            this.isLoggedIn = this.loginService.allowToAccess;
          }
      });
   }

  ngOnInit(): void {
    this.isLoggedIn = this.loginService.allowToAccess;
  }

}
