import { MatToolbar, MatToolbarModule } from '@angular/material/toolbar';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@det/shared.module';
import { ToolbarComponent } from './toolbar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        TranslateModule,
        MatDividerModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        SharedModule,
        MatToolbarModule
    ],
    exports: [
        ToolbarComponent
    ],
    entryComponents: [
        
    ],
    providers: [
        
    ]
})
export class ToolbarModule {
}