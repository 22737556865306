export const locale = {
    lang: 'nb-NO',
    data: {
        'red': 'Rød',
        'pink': 'Rosa',
        'purple': 'Lilla',
        'deep-purple': 'Mørk lilla',
        'indigo': 'Indigo',
        'blue': 'Blå',
        'light-blue': 'Lyseblå',
        'cyan': 'Turkis',
        'teal': 'Blå-grønn',
        'green': 'Grønn',
        'light-green': 'Lysegrønn',
        'lime': 'Lime',
        'yellow': 'Gul',
        'amber': 'Gul-brun',
        'orange': 'Oransje',
        'deep-orange': 'Mørk oransje',
        'brown': 'Brun',
        'grey': 'Grå',
        'blue-grey': 'Blå-grå',
        'dark': 'Mørk',
        'white': 'Hvit',
        'black': 'Svart',
        'SelectColor': 'Velg farge',
        'SuccessTitle': 'Suksess!',
        'ErrorTitle': 'Opps! Vær så snill, prøv på nytt',
        'InfoTitle': 'Info!',
        'WarningTitle': 'Advarsel!',
    }
};



